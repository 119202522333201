import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import ApiAnalytics from '../../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../../../api/model';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
    actionSetSearchDrawListings,
} from '../../../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../../../store/Map/MapSelection/selectors';
import { selectUserListings } from '../../../../../store/Map/UserProfile/selectors';
import MapSearchDrawListingCard from '../../ListingsDrawer/SearchListings/map-search-draw-listing-card';

const ProfileAllMaps = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredListings, setFilteredListings] = useState<ListingDTO[]>([]);

    const listings = useSelector(selectUserListings);
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const dispatch = useDispatch();

    const searchFilter = (title: string, tags: string[], searchTerm: string): boolean => {
        if (searchTerm?.length > 0) {
            const searchTermLowerCase = searchTerm.toLocaleLowerCase();
            return `${title} ${tags?.join(' ')}`.toLocaleLowerCase().includes(searchTermLowerCase);
        }
        return true;
    };

    useEffect(() => {
        setFilteredListings(listings.filter((tl) => searchFilter(tl.title, tl.tags, searchTerm.trim())));
    }, [listings, searchTerm]);

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        dispatch(
            actionSetSearchDrawListings([filteredListings?.find((listing) => listing.id === tileLayerId) as ListingDTO])
        );

        invalidateHighlightedTileLayerGroup();
    };

    const onClickListing = (listingId: number) => {
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.FEATURED_DRAW, listingId);
        Analytics.Event('Side Drawer', 'Clicked To View', listingId);
        UriHelper.navigateToPath(`/maps/${listingId}`);
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
        dispatch(actionSetSearchDrawListings([]));
    };

    const handleHighlightedMap = (listingId: number) => {
        return (
            highlightedTileLayerGroup?.tileLayers.length === 1 &&
            highlightedTileLayerGroup.tileLayers[0].id === listingId
        );
    };

    return (
        <Container>
            <SearchContainer>
                <SearchIcon className="fa fa-search" />
                <SearchInput
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
            </SearchContainer>
            <MapsContainer>
                {filteredListings?.length > 0 ? (
                    <ListingItems>
                        {filteredListings.map((listing, index) => {
                            return (
                                <ListingContainer
                                    key={`${index}-${listing.id}`}
                                    onMouseEnter={() => onMouseEnter(listing.id)}
                                    onMouseLeave={() => onMouseLeave()}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickListing(listing.id);
                                    }}
                                    title={listing.title}
                                    isHighlighted={handleHighlightedMap(listing.id)}
                                    isExiting={false}
                                    index={index}
                                >
                                    <MapSearchDrawListingCard listing={listing} isUserNameDisabled={true} />
                                </ListingContainer>
                            );
                        })}
                    </ListingItems>
                ) : (
                    <ErrorLabel>No Maps were found</ErrorLabel>
                )}
            </MapsContainer>
        </Container>
    );
};

export default ProfileAllMaps;

const Container = styled.div`
    color: white;
`;

const MapsContainer = styled.div`
    margin-top: 10px;
`;

const ListingItems = styled.div`
    max-height: calc(100vh - 160px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const ListingContainer = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;

const SearchContainer = styled.div`
    margin-top: -30px;
    width: 100%;
`;

const SearchIcon = styled.i`
    size: 20px;
    padding: 10px;
    position: relative;
    color: ${(props) => props.theme.color.yellow};
    top: 35px;
`;

const SearchInput = styled.input`
    background: transparent;
    color: #ffffff;
    padding: 5px;
    width: 100%;
    border: 1px solid rgb(255 255 255 / 0.3);
    border-radius: 6px;
    padding-left: 30px;
    min-height: 35px;

    &:focus {
        background: transparent;
        outline: ${(props) => props.theme.color.yellow};
        border: 1px solid ${(props) => props.theme.color.yellow};
        color: #ffffff;
    }
`;

const ErrorLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    height: 25px;
    color: #ffffff;

    opacity: 0.3;
    margin-left: 5px;
`;
