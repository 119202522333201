import styled from 'styled-components';
import { lightDropShadow } from '../../../../../styles/style';
import React from 'react';
import Analytics from '../../../../../lib/user-analytics';

interface MapSearchDrawInputProps {
    expanded: boolean;
    toggleDrawer: () => void;
    mapTitle: string;
}

const MapSearchDrawlabel = ({ expanded, toggleDrawer, mapTitle }: MapSearchDrawInputProps) => {
    return (
        <SearchInputContainer
            id="search-input"
            expanded={expanded}
            onClick={() => {
                Analytics.Event('Map Drawer', 'Opened Mpa');
                toggleDrawer();
            }}
        >
            <SearchInputItem>
                <MapLabel>{mapTitle}</MapLabel>
                <CloseIconContainer>
                    <CloseIcon className="fa fa-chevron-left" />
                </CloseIconContainer>
            </SearchInputItem>
        </SearchInputContainer>
    );
};

export default MapSearchDrawlabel;

const SearchInputContainer = styled.div<{ expanded: boolean }>`
    position: absolute;
    z-index: 99999;
    width: 275px;
    height: 40px;
    left: 85px;
    top: calc(70px + 15px); // 78px is the height of the navbar
    background: ${({ expanded }) => (expanded ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.8)')};
    border-radius: 6px;
    border: ${({ expanded }) => (expanded ? '1px solid rgb(81, 81, 81)' : 'none')};
    ${({ expanded }) => (!expanded ? lightDropShadow : null)};
    transition: background 0.3s ease 0.2s, border 0.3s ease 0.2s;
    cursor: pointer;
`;

const SearchInputItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
`;

const MapLabel = styled.p`
    width: 88%;
    outline: none;
    color: white;
    line-height: 40px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
`;

const LoadingSpinnerContainer = styled.div`
    position: absolute;
    right: 10px;
    height: 20px;
    width: 20px;
`;

const CloseIconContainer = styled(LoadingSpinnerContainer)`
    position: absolute;
    right: 2px;
    top: calc(50% - 12px);
`;

const CloseIcon = styled.i`
    font-size: 18px;
    color: white;
    vertical-align: bottom;
`;
