import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSideDrawerCollapse } from '../../../../store/SideDrawer/selectors';
import { actionSetSideDrawerCollapseAction } from '../../../../store/SideDrawer/actions';

interface DrawerHeaderProps {
    handleBack?: () => void;
    title?: string | React.ReactNode;
    icon?: string;
    collapsible?: boolean;
    backButtonTooltip?: string;
    className?: string;
}

/**
 * DrawerHeader
 * Standardized header for wide drawer
 * @optional handleBack () => void
 * @optional title string | React.ReactNode
 * @optional collapsible boolean
 * Turns on closing drawer functionality and activates icon
 */
// TODO peg for removal
const DrawerHeader = (props: DrawerHeaderProps) => {
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    const dispatch = useDispatch();
    const handleSideDrawerCollapse = useCallback(
        (collapse: boolean) => dispatch(actionSetSideDrawerCollapseAction(collapse)),
        [dispatch]
    );

    useEffect(() => {
        return () => {
            handleSideDrawerCollapse(false);
        };
    }, [handleSideDrawerCollapse]);

    return (
        <Header closeDraw={sideDrawerCollapse} className={props.className}>
            {!props.collapsible && props.handleBack ? (
                <BackArrow onClick={() => props.handleBack && props.handleBack()} />
            ) : null}

            {props.collapsible ? (
                <ChevronIconContainer>
                    <ChevronIcon
                        data-testid="drawer-button-collapse"
                        title="Collapse/Expand Drawer"
                        onClick={() => handleSideDrawerCollapse(!sideDrawerCollapse)}
                        className={sideDrawerCollapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
                    />
                </ChevronIconContainer>
            ) : null}
            {props.title ? <HeaderContent>{props.title}</HeaderContent> : null}
            {props.handleBack ? (
                <CloseIconContainer isTitleVisible={props.title ? true : false}>
                    <CloseIcon
                        data-testid="drawer-button-close"
                        title={props.backButtonTooltip ?? 'Close Drawer'}
                        className="fa fa-close"
                        onClick={() => {
                            props.handleBack && props.handleBack();
                        }}
                    />
                </CloseIconContainer>
            ) : (
                <CloseIconPadding />
            )}
        </Header>
    );
};

export default DrawerHeader;

interface CloseDrawProps {
    closeDraw?: boolean;
    alignTitle?: boolean;
}

export const Header = styled.div<CloseDrawProps>`
    border-bottom: ${(props) => (props.closeDraw ? '' : '1px solid rgba(255, 255, 255, 0.3)')};
    height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: hidden;
`;

export const HeaderContent = styled.div`
    align-content: space-around;
    width: 100%;
`;

const ChevronIconContainer = styled.div`
    user-select: none;
`;

const ChevronIcon = styled.i`
    float: right;
    padding: 15px 0px 0px 10px;
    color: white;
    font-size: 18px;
    cursor: pointer;
`;

const CloseIcon = styled.i`
    float: right;
    padding: 15px 10px 0px 0px;
    font-size: 18px;
    color: white;
    cursor: pointer;
`;

const CloseIconContainer = styled.div<{ isTitleVisible?: boolean }>`
    width: ${(props) => (!props.isTitleVisible ? '100%' : 'auto')};
    user-select: none;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin: 8px;
    float: left;
    cursor: pointer;
`;

const CloseIconPadding = styled.div`
    width: 32px;
`;
