import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';

import { selectLoggedIn } from '../../../../store/Account/selectors';
import PriceWithCurrency from '../../../Shared/price-with-currency';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import DrawerHint from '../../drawer-hint';
import {
    SatelliteSubmitButton,
    Content,
    Divider,
    Title,
    ErrorText,
    SatelliteInputField,
    SatelliteForm,
    SatelliteLabel,
} from '../satellite-drawer-styles';

interface SatelliteOrderCreditCardDetailsProps {
    totalPrice: number; // in dollars
    totalTax?: number;
    currency?: string;
    handleStripeTokenSubmitted: (stripeToken: string) => void;
    stripe: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    error?: Error;
    termsAndConditionsHref: string;
}

const SatelliteOrderCreditCardDetails = (props: SatelliteOrderCreditCardDetailsProps) => {
    const [stripeError, setStripeError] = useState<Error | undefined>(undefined);
    const [stripeLoading, setStripeLoading] = useState(false);
    const [stripeReady, setStripeReady] = useState(false);

    const userLoggedIn = useSelector(selectLoggedIn);

    const isFree = props.totalPrice === 0;

    const handleSubmit = (e) => {
        e.preventDefault();

        Analytics.Event('Satellite', 'Submitted CC');

        if (isFree) {
            props.handleStripeTokenSubmitted('FREE');
            return;
        }
        // Precondition: Stripe has initialized
        if (props.stripe === undefined) {
            setStripeError(Error("Stripe.js hasn't loaded"));
            return;
        }

        // Precondition: User has sign in credentials
        if (!userLoggedIn) {
            setStripeError(Error('You are not signed in.  Please sign in and try again')); // Should never happen
            return;
        }

        setStripeLoading(true);
        setStripeError(undefined);

        props.stripe
            .createToken()
            .then((payload) => {
                if (payload.error) {
                    throw new Error(payload.error.message);
                }

                if (!payload.token) {
                    // Unsure if this can happen - no error and no token from stripe;
                    throw new Error('There was an error with the payment gateway.  Please try again');
                }

                return payload.token.id;
            })
            .then((stripeToken) => {
                setStripeError(undefined);
                if (props.currency && props.totalPrice.toString()) {
                    Analytics.ConversionWithValue(
                        ConversionEvent.COMPLETE_ORDER,
                        props.currency,
                        props.totalPrice.toString()
                    );
                } else {
                    Analytics.Conversion(ConversionEvent.COMPLETE_ORDER);
                }
                props.handleStripeTokenSubmitted(stripeToken);
            })
            .catch((error) => {
                setStripeError(error);
                setStripeLoading(false);
            });
    };

    useEffect(() => {
        setStripeLoading(false);
    }, [props.error]);

    return (
        <Content data-sentry-block>
            <Title>Payment Details</Title>
            <SatelliteForm onSubmit={(e) => handleSubmit(e)}>
                <SatelliteLabel>Card holder name</SatelliteLabel>

                <SatelliteInputField
                    name="cc-name"
                    type="text"
                    id="name"
                    placeholder="Name on card"
                    margin="10px 0px 10px 0px"
                />

                <SatelliteLabel>Card Number</SatelliteLabel>
                <SoarCreditNumberElement
                    onReady={() => setStripeReady(true)}
                    style={{
                        base: {
                            color: 'rgba(255, 255, 255, 0.6)',
                            '::placeholder': {
                                color: 'rgba(255, 255, 255, 0.6)',
                            },
                        },
                    }}
                />
                <CreditCardIcons src="/assets/payments/mastercard-visa-americanexpress.png" />

                <ExpiryAndCVCContainer>
                    <ExpiryContainer>
                        <SatelliteLabel>Expiry</SatelliteLabel>
                        <SoarCardExpiryElement
                            style={{
                                base: {
                                    color: 'rgba(255, 255, 255, 0.6)',
                                    '::placeholder': {
                                        color: 'rgba(255, 255, 255, 0.6)',
                                    },
                                },
                            }}
                        />
                    </ExpiryContainer>

                    <CVCContainer>
                        <SatelliteLabel>CVC</SatelliteLabel>
                        <SoarCardCVCElement
                            style={{
                                base: {
                                    color: 'rgba(255, 255, 255, 0.6)',
                                    '::placeholder': {
                                        color: 'rgba(255, 255, 255, 0.6)',
                                    },
                                },
                            }}
                        />
                    </CVCContainer>
                </ExpiryAndCVCContainer>
            </SatelliteForm>
            {stripeError ? <DrawerHint error>{stripeError.message}</DrawerHint> : null}

            <DrawerHint>
                <PriceHint>
                    By clicking 'Complete Order', you will be charged the total price of: &nbsp;
                    <PriceWithCurrency value={props.totalPrice} currency={props.currency} />
                    {props.totalTax ? ' inc. GST' : ''}
                </PriceHint>
            </DrawerHint>
            <Divider />

            <SatelliteSubmitButton disabled={stripeLoading || !stripeReady} onClick={handleSubmit}>
                {stripeLoading ? (
                    <span>
                        <i className="fa fa-spinner fa-spin" /> Submitting Order
                    </span>
                ) : (
                    'Complete Order'
                )}
            </SatelliteSubmitButton>

            {props.error ? <ErrorText>{props.error.message.replace('400: ', '')}</ErrorText> : null}
        </Content>
    );
};

export default injectStripe(SatelliteOrderCreditCardDetails);

const CreditCardIcons = styled.img`
    max-height: 30px;
    float: right;
`;

const ExpiryAndCVCContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
`;

const ExpiryContainer = styled.div`
    flex: 1;
    margin: 0 18px 0 0;
    width: 110px;
`;

const CVCContainer = styled.div`
    flex: 1;
    width: 110px;
`;

const PriceHint = styled.div`
    color: white;
    line-height: 1.25;
`;

const CreditCardCSS = `
    text-align: left;
    display: block;
    height: 40px;
    padding: 12px 12px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.6) !important;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: 'Manrope', sans-serif !important;

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :active,
    :focus,
    :focus-visible {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;

const SoarCreditNumberElement = styled(CardNumberElement)`
    ${CreditCardCSS}
    width: 100%;
`;

const SoarCardExpiryElement = styled(CardExpiryElement)`
    ${CreditCardCSS}
    width: 90px;
`;

const SoarCardCVCElement = styled(CardCVCElement)`
    ${CreditCardCSS}
    width: 90px;
`;
