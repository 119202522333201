import React, { useEffect } from 'react';
import { ZoomControl as LeafletZoomControl } from 'react-leaflet';
import BoxZoomControl from './box-zoom-control';
import './zoom-control.css';
import { useSelector } from 'react-redux';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import LocationSearchControl from '../../LocationSearch/location-search-control';

const ZoomControl = () => {
    const selectedAnnotation = useSelector(selectSelectedAnnotation);

    useEffect(() => {
        const zoomControls = document.querySelector<HTMLDivElement>('.leaflet-top.leaflet-right');
        if (!zoomControls) return;

        // Shift the zoom control down to accommodate the annotation toolbar
        if (selectedAnnotation) {
            zoomControls.setAttribute('style', 'top: 146px !important');

            if (selectedAnnotation.annotationType === 'TextBox') {
                zoomControls.querySelectorAll('a').forEach((zoomControl) => zoomControl.classList.add('disabled'));
                zoomControls
                    .querySelectorAll('button')
                    .forEach((zoomControlButton) => (zoomControlButton.disabled = true));

                return;
            }
        } else {
            zoomControls.setAttribute('style', 'top: 94px !important');
        }

        zoomControls.querySelectorAll('a').forEach((zoomControl) => zoomControl.classList.remove('disabled'));
        zoomControls
            .querySelectorAll('a')
            .forEach((zoomControl, index) => zoomControl.setAttribute('data-testid', `leaflet-zoom-control-${index}`));
        zoomControls.querySelectorAll('button').forEach((zoomControlButton) => (zoomControlButton.disabled = false));
    }, [selectedAnnotation]);

    return (
        <React.Fragment>
            <LeafletZoomControl position="topright" zoomOutTitle="" zoomInTitle="" />
            <BoxZoomControl />
            <LocationSearchControl />
        </React.Fragment>
    );
};

export default ZoomControl;
