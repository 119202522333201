import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import SideDrawerInfiniteScroll from '../Shared/side-drawer-infinite-scroll';
import { selectMyListings, selectMyMapsLoading, selectMyProfile } from '../../../../store/Account/selectors';
import { useCallback, useEffect, useState } from 'react';
import { actionGetMyListings } from '../../../../store/Account/actions';
import { ListingDTO } from '../../../../api/model';
import SideDrawerSearch from '../Shared/side-drawer-search';

const SideDrawerMyMaps = () => {
    const [myListings, setMyListings] = useState<ListingDTO[] | undefined>(undefined);

    const dispatch = useDispatch();

    const profile = useSelector(selectMyProfile);
    const listings = useSelector(selectMyListings);
    const isLoading = useSelector(selectMyMapsLoading);

    const handleFetchMyListings = useCallback(() => {
        dispatch(actionGetMyListings());
    }, [dispatch]);

    useEffect(() => {
        if (profile && !listings?.length) {
            handleFetchMyListings();
        }
    }, [handleFetchMyListings, listings?.length, profile]);

    useEffect(() => {
        if (listings?.length && !myListings) {
            const filteredListings = listings.filter(
                (listing) => listing.reviewSoar !== 'REJECTED' || listing.review !== 'REJECTED'
            );
            setMyListings(filteredListings);
        }

        return () => {
            setMyListings(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MyMapsDrawer>
            <MyMapsTitle>My Maps</MyMapsTitle>
            <SideDrawerSearch listings={listings || []} setListings={setMyListings} />
            <SideDrawerInfiniteScrollContainer>
                <SideDrawerInfiniteScroll
                    type="MyMaps"
                    listings={myListings || []}
                    exitingListings={new Set()}
                    listingsCount={myListings?.length || 0}
                    filteredCount={myListings?.length || 0}
                    isLoading={isLoading}
                    onLoadMore={() => ''}
                    heightPadding="175px"
                />
            </SideDrawerInfiniteScrollContainer>
        </MyMapsDrawer>
    );
};

export default SideDrawerMyMaps;

const MyMapsDrawer = styled.div`
    margin-top: 15px;
`;

const MyMapsTitle = styled.h1`
    text-align: center;
    font-size: 22px;
    color: white;
`;

const SideDrawerInfiniteScrollContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;
