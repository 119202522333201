import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ApiDraw from '../../api/api-draw';
import UriHelper from '../../lib/uri-helper';
import MobilePolygon from './Annotations/mobile-polygon';
import MobilePolyline from './Annotations/mobile-polyline';
import MobileCircle from './Annotations/mobile-circle';
import MobileRectangle from './Annotations/mobile-rectangle';
import MobileImage from './Annotations/mobile-image';
import MobileFreehandDraw from './Annotations/mobile-freehand';
import MobileText from './Annotations/mobile-text';
import MobileMarker from './Annotations/mobile-marker';
import MobileCoordinate from './Annotations/mobile-coordinate';
import MobileArrows from './Annotations/mobile-arrow';
import DeprecatedAnnotations from '../MapView/Annotations/Project/deprecated-annotations-to-project';
import GeoJSONProject from '../MapView/Annotations/Project/geojson-project';

import { selectActiveAnnotationString } from '../../store/SocialMapping/selectors';
import { actionSetDisplayAnnotations } from '../../store/SocialMapping/actions';
import { useProjectAnnotationHandler } from '../MapView/Annotations/Project/use-project-annotation-handler';
import MobileMilitaryMarker from './Annotations/mobile-military-marker';

const MobileAnnotations = () => {
    const dispatch = useDispatch();

    const commentAnnotations = useSelector(selectActiveAnnotationString);

    // Required for backwards compatibility with old annotations
    const { handleSetProjectAnnotations } = useProjectAnnotationHandler();

    useEffect(() => {
        if (commentAnnotations) {
            const annotations = DeprecatedAnnotations.exportToGeoJSONProject(commentAnnotations);
            if (annotations) {
                const project = GeoJSONProject.import(annotations);
                handleSetProjectAnnotations(project);
                dispatch(actionSetDisplayAnnotations(''));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentAnnotations]);

    // Required for backwards compatibility
    // This was only used briefly but I guess some existing projects might still have this
    const drawProjectId = Number(UriHelper.tryGetParam('drawProjectId'));
    useEffect(() => {
        if (drawProjectId) {
            ApiDraw.getProjectFromShareLink(drawProjectId).then((project) => {
                handleSetProjectAnnotations(project);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawProjectId]);

    return (
        <React.Fragment>
            <MobilePolyline />
            <MobilePolygon />
            <MobileCircle />
            <MobileRectangle />
            <MobileImage />
            <MobileFreehandDraw />
            <MobileText />
            <MobileMarker />
            <MobileCoordinate />
            <MobileArrows />
            <MobileMilitaryMarker />
        </React.Fragment>
    );
};

export default MobileAnnotations;
