import { AppState } from '../root-reducer';

export const selectConfig = (state: AppState) => state.appDomain.config;

export const selectMaintenance = (state: AppState) => state.appDomain.maintenance;

export const selectFlyToMapPosition = (state: AppState) => state.appDomain.mapPosition;

export const selectShouldMapUpdatePositionImmediately = (state: AppState) =>
    state.appDomain.shouldMapPositionUpdateImmediately;

export const selectShouldMapUpdatePositionAtZoomLevel = (state: AppState) => state.appDomain.mapPositionZoom;

export const selectShowLoginDialog = (state: AppState) => state.appDomain.showLoginDialog;

export const selectOnLoginDialogCloseRedirect = (state: AppState) => state.appDomain.onLoginDialogCloseRedirect;

export const selectShowNavbar = (state: AppState) => state.appDomain.showNavbar;

// TODO as we need more from the leafle tmap than just the bounds and zoom
// These can be pegged for removal by a refactor
export const selectMapBounds = (state: AppState) => state.appDomain.mapBounds;

export const selectMapZoom = (state: AppState) => state.appDomain.mapZoom;

export const selectLeafletMap = (state: AppState) => state.appDomain.leafletMap;

export const selectShowUploadDialog = (state: AppState) => state.appDomain.showUploadDialog;

export const selectIsMapMoving = (state: AppState) => state.appDomain.isMapMoving;
