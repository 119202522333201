import L from 'leaflet';

/**
 * Allows adjusting on the client of the AOI bounds in pixel space
 * @param map - the map object
 * @param margins - the margins in pixel space to adjust the bounds by (top, right, bottom, left) all optional
 * @returns - the adjusted bounds
 */
export const adjustedMapBounds = (
    map: L.Map,
    margins: { top?: number; right?: number; bottom?: number; left?: number } = {}
): L.LatLngBounds => {
    const mapBounds = map.getBounds();
    const sw = mapBounds.getSouthWest();
    const ne = mapBounds.getNorthEast();

    const mapSize = map.getSize();
    const mapWidth = mapSize.x;
    const mapHeight = mapSize.y;

    const pixelTopMargin = margins.top ?? 0;
    const pixelBottomMargin = margins.bottom ?? 0;
    const pixelLeftMargin = margins.left ?? 0;
    const pixelRightMargin = margins.right ?? 0;

    const mapLatSpan = ne.lat - sw.lat;
    const mapLngSpan = ne.lng - sw.lng;

    const newSwLat = sw.lat + mapLatSpan * (pixelBottomMargin / mapHeight);
    const newSwLng = sw.lng + mapLngSpan * (pixelLeftMargin / mapWidth);
    const newNeLat = ne.lat - mapLatSpan * (pixelTopMargin / mapHeight);
    const newNeLng = ne.lng - mapLngSpan * (pixelRightMargin / mapWidth);

    const adjustedBounds = L.latLngBounds(L.latLng(newSwLat, newSwLng), L.latLng(newNeLat, newNeLng));

    // LEAVE ME HERE FOR VISUAL DEBUGGING
    // const polygonCoordinates: L.LatLngTuple[] = [
    //     [newSwLat, newSwLng],
    //     [newSwLat, newNeLng],
    //     [newNeLat, newNeLng],
    //     [newNeLat, newSwLng],
    // ];

    // L.polygon(polygonCoordinates, { color: 'red', weight: 2 }).addTo(map);

    return adjustedBounds;
};
