import { LatLng, LatLngBounds } from 'leaflet';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import ApiAnalytics from '../../api/api-analytics';
import ApiAutocomplete from '../../api/api-autocomplete';
import { AnalyticsAction, AnalyticsNote, AutocompleteDTO, AutocompleteType } from '../../api/model';
import GeoUtil from '../../lib/geo-util';
import SoarHelper from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { actionFlyToOnMap } from '../../store/App/actions';
import { actionSetSubdomainTileLayerInactive } from '../../store/Map/Subdomain/actions';
import { actionActiveMapCleared, actionActiveMapFetchById } from '../../store/Map/SuperMap/actions';
import { actionClearDroneImageUpload } from '../../store/Map/Uploads/actions';
import { actionClearActiveSearch, actionUpdateActiveSearchPosition } from '../../store/Search/actions';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../store/SideDrawer/selectors';
import AutocompleteSearchBox from './autocomplete-search-box';

interface SoarAutocompleteProps {
    autocompleteType?: AutocompleteType[];
    onLatlngSelected?: (position: LatLng) => void;
    placeholderAddress?: string;
}

const SoarAutocomplete = (props: SoarAutocompleteProps) => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    const dispatch = useDispatch();
    const flyTo = (position: LatLng | LatLngBounds) => dispatch(actionFlyToOnMap(position));
    const setMapActive = (id: number) => dispatch(actionActiveMapFetchById(id));
    const handleUpdateActivePosition = (position: LatLng) => dispatch(actionUpdateActiveSearchPosition(position));
    const handleClearActive = () => dispatch(actionClearActiveSearch());

    const DEBOUNCE_TIME = 300; //milliseconds

    const [searchResults, setSearchResults] = useState<AutocompleteDTO[]>([]);
    const [searchTerm] = useState(() => new Subject<string>());
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        const subscription = searchTerm
            .pipe(
                filter((value) => value.length > 2),
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(),
                tap((_) => setIsSearching(true)),
                switchMap((value) => {
                    Analytics.Event('Search', 'Returned search results', value);
                    return value.length > 2
                        ? ApiAutocomplete.autocomplete(
                              value,
                              props.autocompleteType ? props.autocompleteType : ['ADDRESS', 'LISTING', 'USER']
                          )
                        : Promise.resolve<AutocompleteDTO[]>([]);
                })
            )
            .subscribe((next) => {
                setSearchResults(next);
                setIsSearching(false);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * SEARCH CONTROL SIDEDRAWER BEHAVIOURS
     * Below handles the sidedrawer when a user enters a search
     * */

    const resetSideDrawerOnListingSearch = () => {
        resetDrawerForMaps(sideDrawerMode);
    };

    const resetSideDrawerOnMagicOrLatLngSearch = () => {
        resetDrawerForMaps(sideDrawerMode);
    };

    const resetDrawerForMaps = (sideDrawerMode: SideDrawerMode) => {
        const resetToMapsDrawer = [
            SideDrawerMode.MAPS,
            SideDrawerMode.SUBDOMAIN_MAPS,
            SideDrawerMode.SHARE_MAP,
            SideDrawerMode.YOUR_MAPS,
        ];
        // If a user uses search and is currently interacting with maps or uploading, reset to the default map drawer
        if (resetToMapsDrawer.includes(sideDrawerMode)) {
            dispatch(actionActiveMapCleared());
            dispatch(actionSetSubdomainTileLayerInactive());
            dispatch(actionClearDroneImageUpload());

            SoarHelper.isSoar()
                ? UriHelper.navigateToDrawer(SideDrawerMode.MAPS)
                : UriHelper.navigateToDrawer(SideDrawerMode.SUBDOMAIN_MAPS);
        }
    };

    const handleSearchResultSelected = (searchResult: AutocompleteDTO) => {
        if (searchResult.type === 'LISTING' && searchResult.listingId) {
            resetSideDrawerOnListingSearch();
            handleClearActive();
            setMapActive(searchResult.listingId);
            // No need to handle the animated-maps as it does not come up in the search results
            if (searchResult.listingId) {
                UriHelper.navigateToMap(searchResult.listingId);
            }
            Analytics.Event('Clicked to view', 'Map - searched', searchResult.listingId);
            ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.SEARCH, searchResult.listingId);
            const latLngBounds = GeoUtil.latLngBoundsFromPolygonWKT(searchResult.geometryWKT);
            if (props.onLatlngSelected) {
                props.onLatlngSelected(latLngBounds.getCenter());
            } else {
                flyTo(latLngBounds);
            }
        } else if (searchResult.type === 'ADDRESS' && searchResult.geometryWKT) {
            const latlng = GeoUtil.latLngFromWKT(searchResult.geometryWKT);
            if (props.onLatlngSelected) {
                props.onLatlngSelected(latlng);
            } else {
                handleUpdateActivePosition(latlng);
                flyTo(latlng);
            }
        } else if (searchResult.type === 'ADDRESS' && searchResult.magicKey) {
            ApiAutocomplete.autocompleteFromMagic(searchResult.magicKey)
                .then((res) => {
                    if (res.length > 0) {
                        const latlng = GeoUtil.latLngFromWKT(res[0].geometryWKT);
                        const latlngBounds = GeoUtil.latLngBoundsFromPolygonWKT(res[0].extentWKT);
                        if (props.onLatlngSelected) {
                            props.onLatlngSelected(latlng);
                        } else {
                            handleUpdateActivePosition(latlng);
                            flyTo(latlngBounds ? latlngBounds : latlng);
                        }
                        resetSideDrawerOnMagicOrLatLngSearch();
                    } else {
                        throw new Error('Suggestions has incorrect magicKey');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (searchResult.type === 'USER') {
            UriHelper.navigateToPath(`/profile/${searchResult.userId}`);
        } else {
            toast.info(`Suggestions is missing location`);
        }
        setSearchResults([]);
        searchTerm.next('');
    };

    return (
        <AutocompleteSearchBox
            searchResults={searchResults}
            onFetchRequest={(search) => {
                searchTerm.next(search);
            }}
            onSearchResultSelected={(searchResult) => {
                handleSearchResultSelected(searchResult);
            }}
            onCoordinateSelected={(coordinate) => {
                if (props.onLatlngSelected) {
                    props.onLatlngSelected(coordinate);
                } else {
                    resetSideDrawerOnMagicOrLatLngSearch();
                    handleUpdateActivePosition(coordinate);
                    flyTo(coordinate);
                }
            }}
            placeholder={'Search for location or place...'}
            isLoading={isSearching}
            placeholderAddress={props.placeholderAddress}
        />
    );
};

export default SoarAutocomplete;
