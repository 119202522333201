import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BackgroundGradientAnimation from './Animations/background-gradient-animation';
import { JoinPageParameters } from './join';
import Register from '../Registration/register';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/Account/selectors';
import ApiAnalytics from '../../api/api-analytics';
import UriHelper from '../../lib/uri-helper';

const JoinMobile = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const [hasPageJustLoaded, setHasPageJustLoaded] = useState(true);
    const [label, setLabel] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState('');

    const handlePageLoaded = (label: string | undefined) => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'View', label, 'Mobile');
    };

    const handlePageLoadedWhileAlreadyLoggedIn = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'User already logged in', label, 'Mobile');
        UriHelper.navigateToPath('/maps');
    };

    const handleSuccessfulRegistration = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'Registered', label, 'Mobile');
        UriHelper.navigateToPath('/maps');
    };

    useEffect(() => {
        setTimeout(() => {
            setHasPageJustLoaded(false);
        }, 1000);
    }, []);

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.touchAction = 'auto';
        document.getElementsByTagName('body')[0].style.overscrollBehaviorY = 'unset';

        return () => {
            document.getElementsByTagName('body')[0].style.touchAction = 'none';
            document.getElementsByTagName('body')[0].style.overscrollBehaviorY = 'none !important';
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const parameter = urlParams.get('q');
        if (!parameter) return;
        const label = JoinPageParameters.find((param) => param.value === parameter)?.label;
        setLabel(label);
        handlePageLoaded(label);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            if (hasPageJustLoaded) {
                handlePageLoadedWhileAlreadyLoggedIn();
            } else {
                handleSuccessfulRegistration();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            <BackgroundGradientAnimation />

            <Title>Find the {label ? <span>{label}</span> : null} maps you are looking for</Title>

            <Container>
                <Register
                    email={email}
                    onEmailChange={(email: string) => setEmail(email)}
                    onClose={() => {
                        null;
                    }}
                    handleClickLogin={() => {
                        null;
                    }}
                    onRegisterSuccess={() => {
                        null;
                    }}
                    hideFooter={true}
                />
            </Container>

            <ImageContainer>
                <Image src="/assets/join-page/asset1.png" />
                <Image src="/assets/join-page/asset2.png" />
                <Image src="/assets/join-page/asset3.gif" />
            </ImageContainer>
        </React.Fragment>
    );
};

export default JoinMobile;

const Container = styled.div`
    margin: 20px;
    border: 1px solid #eed926;
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const Title = styled.h2`
    color: ${({ theme }) => theme.color.white};
    font-size: 2.5rem;
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;

    span {
        color: ${({ theme }) => theme.color.yellow};
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    max-width: 25%;
    height: 80%;
    margin: 10px;
`;
