import React, { useEffect, useState } from 'react';
import ApiDraw, { ProjectResponse } from '../../../../api/api-draw';
import { useDispatch } from 'react-redux';
import GeoJSONProject from '../../../MapView/Annotations/Project/geojson-project';
import { actionSetSideDrawerModeAction } from '../../../../store/SideDrawer/actions';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import SentinelShareModal from '../../../Drawer/Share/sentinel-share-modal';
import { CustomCookieConsent } from '../../../Shared/custom-cookie-consent';
import MobileSoarLogo from '../../Sharing/mobile-soar-logo';
import MobileStoryMap from './mobile-story-map';
import MobileStoryMapBottomSheet from './mobile-story-map-bottom-sheet';
import ProjectAccess from '../../../MapView/Annotations/Project/project-access';
import { DrawPage, DrawPageMapLayer, parseMapLayers } from '../../../MapView/Annotations/StoryMaps/draw-story';

interface ViewStoryMapProps {
    drawProjectId: number;
    drawerMode: SideDrawerMode;
}

const MobileViewStoryMap = (props: ViewStoryMapProps) => {
    const [drawPages, setDrawPages] = useState<DrawPage[]>([]);
    const [projectData, setProjectData] = useState<ProjectResponse | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionSetSideDrawerModeAction(props.drawerMode));
    }, [dispatch, props.drawerMode]);

    useEffect(() => {
        ApiDraw.getDrawProject(props.drawProjectId)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((res: any) => {
                if (res?.data) {
                    // TODO this will unbreak it all but do not know the strcuture of the "story object"
                    // So just using the original response for now
                    setProjectData(res);
                }
                if (res.data && res.data.pages) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const pagesRaw: any[] = res.data.pages;
                    const pages: DrawPage[] = pagesRaw.map((page, index) => {
                        const project = GeoJSONProject.import(JSON.stringify(page));

                        const mapLayers =
                            page.mapLayers?.map((mapLayer) => parseMapLayers(mapLayer)) ?? ([] as DrawPageMapLayer[]);

                        if (page.map) {
                            mapLayers.push({
                                listingId: page.map.id,
                                title: page.map.title,
                                author: page.map.author,
                                opacity: page.map.opacity ?? 1,
                            });
                        }

                        const drawPage: DrawPage = {
                            projectTitle: res.title,
                            pageTitle: page.pageTitle || page.title,
                            index: index,
                            id: page.project.id,
                            description: page.description || res.description,
                            project: project,
                            mapLayers,
                            viewportLocked: page.viewportLocked,
                            allViewportsLocked: page.allViewportsLocked || false,
                            activeBaseMap: page.activeBaseMap,
                        };
                        return drawPage;
                    });

                    setDrawPages(pages);
                } else if (res.data) {
                    const mapLayers =
                        res.data?.project?.mapLayers.map((mapLayer) => parseMapLayers(mapLayer)) ??
                        ([] as DrawPageMapLayer[]);

                    if (res.mapIds && res.mapIds.length) {
                        mapLayers.push({
                            listingId: res.mapIds[0],
                            title: 'Map Layer',
                            author: 'Soar User',
                            opacity: 1,
                        });
                    }

                    const drawPage: DrawPage = {
                        projectTitle: res.title,
                        index: 0,
                        id: res.id,
                        description: res.description,
                        project: GeoJSONProject.import(JSON.stringify(res.data)),
                        mapLayers,
                        viewportLocked: res.viewportLocked,
                        allViewportsLocked: res.allViewportsLocked || false,
                        activeBaseMap: res.activeBaseMap,
                    };
                    setDrawPages([drawPage]);
                }
            })
            .catch((error) => {
                setError(error.message);
            });
    }, [props.drawProjectId, error]);

    // TODO If the native is split out we will not have to do this but for now this will remove the logo when uisng the /mobile/foo routes
    const isWebMobile = props.drawerMode !== SideDrawerMode.MOBILE_NATIVE;

    return (
        <React.Fragment>
            <MobileStoryMap
                drawPages={drawPages}
                currentPageIndex={currentPageIndex}
                handleCurrentPage={setCurrentPageIndex}
            />
            {projectData && drawPages && (
                <MobileStoryMapBottomSheet
                    page={drawPages[currentPageIndex] || projectData}
                    numberOfPages={drawPages.length}
                    owner={{
                        userName: projectData.ownerName || 'Soar User',
                        userId: projectData.ownerId,
                        reputable: projectData.ownerReputable || false,
                    }}
                />
            )}
            {isWebMobile && <MobileSoarLogo />}
            <SentinelShareModal />
            <CustomCookieConsent />
            {error ? <ProjectAccess error={error} resetError={() => setError(undefined)} /> : null}
        </React.Fragment>
    );
};

export default MobileViewStoryMap;
