import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import {
    selectActiveDrawProject,
    selectActiveDrawProjectIsFetching,
    selectDrawStoryProjects,
    selectDrawStoryProjectsIsFetching,
} from '../../store/Map/DrawProject/selectors';
import { selectActiveAllMapIsFetching } from '../../store/Map/SuperMap/selectors';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../store/SideDrawer/selectors';
import StoryBuilderSidedrawer from '../MapView/Annotations/StoryMaps/StoryBuilder/story-builder-sidedrawer';
import StoryMapsDrawerTitle from '../MapView/Annotations/StoryMaps/StoryView/story-maps-drawer-title';
import ActiveMapLoading from './Maps/ActiveMap/active-map-loading';
import Drawer from './Shared/Drawer/drawer';
import DrawerContent from './Shared/Drawer/drawer-content';
import DrawerHeader, { HeaderContent } from './Shared/Drawer/drawer-header';
import ThinDrawer from './Shared/Drawer/thin-drawer';
import ActiveProjectDrawer from './SoarDraw/active-project-drawer';
import ActiveStoryMapProjectDrawer from './SoarDraw/active-story-map-project-drawer';

const DrawerControl = () => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);

    const activeDrawProject = useSelector(selectActiveDrawProject);
    const activeDrawProjectIsFetching = useSelector(selectActiveDrawProjectIsFetching);

    const activeDrawStoryProjects = useSelector(selectDrawStoryProjects);
    const activeDrawStoryProjectsIsFetching = useSelector(selectDrawStoryProjectsIsFetching);

    if (sideDrawerMode === SideDrawerMode.HIDDEN) {
        return <React.Fragment />;
    }

    if (sideDrawerMode === SideDrawerMode.SOAR_DRAW_EDIT || sideDrawerMode === SideDrawerMode.SOAR_DRAW_NEW) {
        return <StoryBuilderSidedrawer />;
    }

    if (activeDrawProject && sideDrawerMode === SideDrawerMode.SOAR_PROJECT) {
        return (
            <ThinDrawer>
                <DrawerHeader
                    backButtonTooltip="Close Stoary"
                    collapsible
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                />
                <DrawProjectDrawerContent>
                    <ActiveProjectDrawer
                        drawProject={activeDrawProject}
                        handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    />
                </DrawProjectDrawerContent>
            </ThinDrawer>
        );
    }

    if (activeDrawStoryProjects?.length && sideDrawerMode === SideDrawerMode.SOAR_PROJECT) {
        return (
            <ThinDrawer>
                <DrawerHeader
                    collapsible
                    backButtonTooltip="Close Draw Stoary"
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    title={<StoryMapsDrawerTitle />}
                />
                <DrawProjectDrawerContent>
                    <ActiveStoryMapProjectDrawer
                        drawProjectArray={activeDrawStoryProjects}
                        handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    />
                </DrawProjectDrawerContent>
            </ThinDrawer>
        );
    }

    if (activeMapIsFetching || activeDrawProjectIsFetching || activeDrawStoryProjectsIsFetching) {
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Map"
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    collapsible
                />
                <DrawerContent>
                    <ActiveMapLoading />
                </DrawerContent>
            </Drawer>
        );
    }

    return <React.Fragment></React.Fragment>;
};

export default DrawerControl;

export const DrawProjectDrawerHeader = styled(DrawerHeader)`
    ${HeaderContent} {
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: ' ';
            display: inline-block;
            width: 42px;
            height: 42px;
            background: url(/assets/stoaries-icons/book.svg) no-repeat center;
            margin-right: 8px;
        }

        &:after {
            content: ' ';
            display: inline-block;
            width: 150px;
            height: 20px;
            background: url(/assets/stoaries-icons/title.svg) no-repeat center;
        }
    }
`;

export const DrawProjectDrawerContent = styled(DrawerContent)`
    min-height: 0px;
`;
