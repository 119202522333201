import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import Analytics from '../../../../lib/user-analytics';
import { selectHighlightedListingGroup } from '../../../../store/Map/MapSelection/selectors';
import MapSearchDrawListingCard from '../ListingsDrawer/SearchListings/map-search-draw-listing-card';
import MapSearchDrawPreviewDetails from '../ListingsDrawer/SearchListings/map-search-draw-preview-details';
import SideDrawerLoader from './side-drawer-loader';
import SideDrawerNested from './side-drawer-nested';
import SideDrawerNoMaps from './side-drawer-no-maps';

export type MapDrawerTypes = 'MapSearch' | 'Bookmarks' | 'MyMaps';
interface MapSearchDrawAOIResultsProps {
    type: MapDrawerTypes;
    listings: ListingDTO[] | undefined;
    exitingListings: Set<number>;
    listingsCount: number;
    filteredCount: number;
    isLoading?: boolean;
    onLoadMore: () => void;
    heightPadding: string;
    onScrollTo?: (scrollY: number) => void;
    initialScrollPosition?: number;
}

const SideDrawerInfiniteScroll = ({
    type,
    listings,
    exitingListings,
    listingsCount,
    filteredCount,
    isLoading,
    onLoadMore,
    heightPadding,
    onScrollTo,
    initialScrollPosition,
}: MapSearchDrawAOIResultsProps) => {
    const [nestedListingPreview, setNestedListingPreview] = useState<ListingDTO>();
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);
    const ref = useRef<InfiniteScroll | null>(null);

    const handleHighlightedMap = (listingId: number) => {
        return (
            highlightedTileLayerGroup?.tileLayers.length === 1 &&
            highlightedTileLayerGroup.tileLayers[0].id === listingId
        );
    };

    return (
        <React.Fragment>
            <ResultsContainer
                ref={ref}
                onScroll={() => {
                    if (ref.current && onScrollTo) {
                        // For some reason, the InfiniteScroll component makes this a private property
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const scrollY = (ref.current as any).lastScrollTop;
                        onScrollTo(scrollY);
                    }
                }}
                initialScrollY={initialScrollPosition || 0}
                dataLength={listings?.length ?? 0}
                next={onLoadMore}
                hasMore={(listings?.length ?? 0) < listingsCount}
                loader={(listings?.length ?? 0) < listingsCount && filteredCount !== 0 && <SideDrawerLoader />}
                height={`calc(100vh - ${heightPadding})`} // height of the header and a trim off the bottom
            >
                {listings &&
                    listings.map((listing, index) => (
                        <React.Fragment>
                            <SideDrawerNested
                                key={`${listing.id}-${index}`}
                                closeDrawer={nestedListingPreview?.id !== listing.id}
                                width={'500px'}
                                controlText={`View ${listing.title}`}
                                controlComponent={
                                    <Container
                                        key={`${index}-${listing.id}`}
                                        onClick={() => {
                                            Analytics.Event('Side Drawer', 'Clicked list item to view preview details');
                                            setNestedListingPreview(listing);
                                            return;
                                        }}
                                        title={listing.title}
                                        isHighlighted={handleHighlightedMap(listing.id)}
                                        isExiting={exitingListings.has(listing.id)}
                                        index={index}
                                    >
                                        <MapSearchDrawListingCard listing={listing} />
                                    </Container>
                                }
                            >
                                <MapSearchDrawPreviewDetails listing={listing} />
                            </SideDrawerNested>
                        </React.Fragment>
                    ))}
                {listings?.length === 0 && !isLoading ? <SideDrawerNoMaps type={type} /> : null}
                {isLoading && listingsCount === 0 && exitingListings?.size === 0 && <SideDrawerLoader />}
            </ResultsContainer>
        </React.Fragment>
    );
};

export default SideDrawerInfiniteScroll;

const ResultsContainer = styled(InfiniteScroll)`
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const Container = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;
