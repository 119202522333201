import { isMobileVersion } from '../../../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import ApiAnalytics from '../../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../../../api/model';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
    actionSetSearchDrawListings,
} from '../../../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../../../store/Map/MapSelection/selectors';
import ProfileAllMaps from './profile-all-maps';
import SideDrawerNested from '../../Shared/side-drawer-nested';
import MapSearchDrawListingCard from '../../ListingsDrawer/SearchListings/map-search-draw-listing-card';
import { useEffect, useRef, useState } from 'react';
import { useWindowHeight } from '@react-hook/window-size';

interface ProfileFeaturedMapsProps {
    listings: ListingDTO[];
}

const ProfileFeaturedMaps = ({ listings }: ProfileFeaturedMapsProps) => {
    const [displayCardCount, setDisplayCardCount] = useState<number>(0);

    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const screenHeight = useWindowHeight();

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        dispatch(actionSetSearchDrawListings([listings?.find((listing) => listing.id === tileLayerId) as ListingDTO]));

        invalidateHighlightedTileLayerGroup();
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
        dispatch(actionSetSearchDrawListings([]));
    };

    const onClickListing = (listingId: number) => {
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.FEATURED_DRAW, listingId);
        Analytics.Event('Side Drawer', 'Clicked To View', listingId);
        UriHelper.navigateToPath(`/maps/${listingId}`);
    };

    const handleHighlightedMap = (listingId: number) => {
        return (
            highlightedTileLayerGroup?.tileLayers.length === 1 &&
            highlightedTileLayerGroup.tileLayers[0].id === listingId
        );
    };

    useEffect(() => {
        if (containerRef?.current && listings?.length) {
            setDisplayCardCount(Math.floor(containerRef.current.clientHeight / 90));
        }
    }, [containerRef, listings?.length, screenHeight]);

    return (
        <Container ref={containerRef}>
            {listings?.length > 0 ? (
                <ProfileHeader>
                    <Title>Recent Maps</Title>
                    <SideDrawerNested
                        controlText="All Maps"
                        controlComponent={
                            <AllMapsButton>
                                <AllMapsLabel>All Maps</AllMapsLabel>
                                <AllMapsArrow src="/assets/user-profile-logos/arrow.png" />
                            </AllMapsButton>
                        }
                    >
                        <ProfileAllMaps />
                    </SideDrawerNested>
                </ProfileHeader>
            ) : null}

            {displayCardCount > 0 &&
                listings?.slice(0, isMobileVersion ? 8 : displayCardCount).map((listing, index) => {
                    return (
                        <ListingContainer
                            key={`${index}-${listing.id}`}
                            onMouseEnter={() => onMouseEnter(listing.id)}
                            onMouseLeave={() => onMouseLeave()}
                            onClick={(e) => {
                                e.preventDefault();
                                onClickListing(listing.id);
                            }}
                            title={listing.title}
                            isHighlighted={handleHighlightedMap(listing.id)}
                            isExiting={false}
                            index={index}
                        >
                            <MapSearchDrawListingCard listing={listing} isUserNameDisabled={true} />
                        </ListingContainer>
                    );
                })}
        </Container>
    );
};

export default ProfileFeaturedMaps;

const Container = styled.div`
    color: white;
    padding: 12px 0px 0px 8px;
    height: calc(100vh - (440px + 70px)); // profile details and nav
`;

const ProfileHeader = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

const Title = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
`;

const AllMapsButton = styled.div`
    cursor: pointer;
    float: right;
    margin-top: -22px;
    margin-right: 8px;
`;

const AllMapsLabel = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    opacity: 0.3;
    margin-right: 5px;
`;

const AllMapsArrow = styled.img`
    margin-bottom: 4px;
    margin-left: 5px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const ListingContainer = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;
