import { DrawPage, DrawProject } from '../../../components/MapView/Annotations/StoryMaps/draw-story';
import { setArrowsAction } from '../../Annotations/Arrow/actions';
import { setCirclesAction } from '../../Annotations/Circle/actions';
import { setCoordinatesAction } from '../../Annotations/Coordinate/actions';
import { setFreehandDrawsAction } from '../../Annotations/Freehand/actions';
import { setImagesAction } from '../../Annotations/Images/actions';
import { setMarkersAction } from '../../Annotations/Marker/actions';
import { setPolylinesAction } from '../../Annotations/Path/actions';
import { setPolygonsAction } from '../../Annotations/Polygon/actions';
import { setRectanglesAction } from '../../Annotations/Rectangle/actions';
import { setTextBoxesAction } from '../../Annotations/TextBox/actions';
import { deleteAllAnnotationsAction, setSelectedAnnotationAction } from '../../Annotations/actions';
import { actionActiveMapCleared, actionActiveMapFetchById } from '../SuperMap/actions';
import { actionTypes as at } from './constants';

export const actionSetDrawStoryBuilderActive = (isActive: boolean) => {
    return {
        type: at.DRAW_STORY_BUILDER_ACTIVE,
        payload: isActive,
    };
};

export const actionSetDrawProject = (drawProject: DrawProject) => {
    return {
        type: at.SET_DRAW_STORY,
        payload: drawProject,
    };
};

export const actionSetDrawStoryId = (drawStoryId: string) => {
    return {
        type: at.SET_DRAW_STORY_ID,
        payload: drawStoryId,
    };
};

export const actionClearDrawStory = () => {
    return {
        type: at.SET_DRAW_STORY_ID,
        package: undefined,
    };
};

export const actionSetDrawPage = (drawPage: DrawPage) => {
    deleteAllAnnotationsAction();
    if (drawPage.project) {
        setArrowsAction(drawPage.project.arrows);
        setMarkersAction(drawPage.project.markers);
        setPolylinesAction(drawPage.project.polylines);
        setPolygonsAction(drawPage.project.polygons);
        setCirclesAction(drawPage.project.circles);
        setRectanglesAction(drawPage.project.rectangles);
        setFreehandDrawsAction(drawPage.project.freehandDraws);
        setArrowsAction(drawPage.project.arrows);
        setImagesAction(drawPage.project.images);
        setTextBoxesAction(drawPage.project.textBoxes);
        setCoordinatesAction(drawPage.project.coordinates);
        setSelectedAnnotationAction(undefined);
    }

    if (drawPage.map) {
        actionActiveMapFetchById(drawPage.map.id);
    } else {
        actionActiveMapCleared();
    }

    return {
        type: at.SET_DRAW_PAGE,
        payload: drawPage,
    };
};

export const actionSetHighlightedStoaryWKT = (highlightedStoaryWKT: string | undefined) => {
    return {
        type: at.SET_HIGHLIGHTED_STOARY_WKT,
        payload: highlightedStoaryWKT,
    };
};
