import { Basemap } from '../../../../store/Map/Basemap/model';
import { Project } from '../Project/geojson-project';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import { LatLng, LatLngBounds } from 'leaflet';

export interface DrawProject {
    title: string;
    id: string;
    drawPages: DrawPage[];
}

export interface DrawPage {
    projectTitle?: string;
    pageTitle?: string;
    index: number;
    description: string;
    id: string;
    project: Project;

    viewportLocked?: boolean;
    allViewportsLocked?: boolean;
    map?: DrawPageMap;
    activeMap?: number;
    activeBaseMap?: Basemap;
    mapLayers?: DrawPageMapLayer[];

    // Specific to viewing story and not creating or editing
    // to be reviewed as another type
    userName?: string;
    userId?: string;
    ownerReputable?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

export interface DrawPageMap {
    id: number;
    title: string;
    author: string;
    opacity?: number;
}

export interface DrawPageMapLayer {
    title: string;
    author: string;
    opacity: number;
    listingId?: number;
    satelliteFeature?: SentinelFeature;
}

export const parseSatellite = (mapLayer) => {
    const createLatLngBounds = (bounds: { _northEast; _southWest }): LatLngBounds => {
        const { _northEast, _southWest } = bounds;

        const northEast = new LatLng(_northEast.lat, _northEast.lng);
        const southWest = new LatLng(_southWest.lat, _southWest.lng);

        return new LatLngBounds(southWest, northEast);
    };

    const bbox = createLatLngBounds(mapLayer?.satelliteFeature?.bbox);

    const satelliteFeature = {
        ...mapLayer?.satelliteFeature,
        bbox: bbox,
        transparency: mapLayer.transparency,
    };

    return satelliteFeature;
};

/* used to parse old implementation of the mapLayers */
export const parseMapLayers = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapLayer: any
): DrawPageMapLayer => {
    if (mapLayer.mapId) {
        return {
            title: 'Map Layer',
            listingId: mapLayer.mapId,
            author: 'Soar User',
            opacity: mapLayer.transparency,
        };
    }
    if (mapLayer.satelliteFeature && mapLayer.transparency) {
        return {
            title: `${mapLayer.satelliteFeature.layer.replace(/_/im, ' ')} ${new Date(
                mapLayer.satelliteFeature.date
            ).toLocaleDateString()}`,
            author: mapLayer.satelliteFeature.satellite,
            opacity: mapLayer.transparency,
            satelliteFeature: mapLayer.satelliteFeature,
        };
    }
    return { ...mapLayer, opacity: mapLayer.opacity || 1 };
};
