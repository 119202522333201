import L from 'leaflet';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMapBounds } from '../../store/App/selectors';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import {
    SegmentContainer,
    SegmentTitle,
    SegmentTitleIcon,
    SegmentHeader,
    SegmentFilters,
    SegmentContent,
    Select,
    SelectOption,
    LayerSelect,
    DateSelect,
    SatelliteSelect,
} from './search-modal';

import { DateRange, last30Days } from '../Drawer/Satellites/Sentinel/sentinel-filter';
import { SentinelMetadata } from '../../store/Map/Sentinel/model';
import { getFeatures, cancelFeatures } from '../../lib/sentinel-service';
import { PulseLoader } from '../Shared/pulse-loader';
import Constants from '../../constants';
import SatelliteUtil from '../../lib/satellite-util';
import Analytics from '../../lib/user-analytics';

interface SearchContentSentinelInViewportProps {
    onSelectItem: (mapLayer: CarouselItem) => void;
}

type ProviderFilter = 'SENTINEL' | 'LANDSAT';

const SearchContentSentinelInViewport = (props: SearchContentSentinelInViewportProps) => {
    const aoi = useSelector(selectMapBounds);
    const [fetchingSentinel, setFetchingSentinel] = useState(false);
    const [sentinelFeatures, setSentinelFeatures] = useState<CarouselItem[]>([]);
    const [sentinelError, setSentinelError] = useState<string | undefined>();

    const [filterLayer, setFilterLayer] = useState<SentinelMetadata>(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
    const [filterProvider, setFilterProvider] = useState<ProviderFilter>('SENTINEL');
    const [filterDate, setFilterDate] = useState<DateRange>(last30Days);

    const currentDate = moment();
    const dateFilterOptions: DateRange[] = [
        {
            startDate: currentDate.clone().subtract(1, 'months').toDate(),
            endDate: currentDate.toDate(),
            displayText: currentDate.format('MMMM YYYY'),
        },
    ];

    const optionDate = currentDate;
    while (optionDate.year() >= 2016 && optionDate.month() >= 0) {
        optionDate.subtract(1, 'months');

        dateFilterOptions.push({
            startDate: optionDate.clone().subtract(1, 'months').toDate(),
            endDate: optionDate.toDate(),
            displayText: optionDate.format('MMMM YYYY'),
        });
    }

    const handleSelectLayer = (layer: string) => {
        switch (layer) {
            case 'TRUE_COLOR':
                setFilterLayer(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
                break;
            case 'FALSE_COLOR':
                setFilterLayer(Constants.OVERLAY_DATA.SENTINEL.FALSE_COLOR);
                break;
            case 'GEOLOGY':
                setFilterLayer(Constants.OVERLAY_DATA.SENTINEL.GEOLOGY);
                break;
            case 'NDVI':
                setFilterLayer(Constants.OVERLAY_DATA.SENTINEL.NDVI);
                break;
            case 'SENTINEL_1_RADAR':
                setFilterLayer(Constants.OVERLAY_DATA.SENTINEL.SENTINEL_1_RADAR);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (aoi && aoi instanceof L.LatLngBounds) {
            setSentinelFeatures([]);
            setSentinelError(undefined);
            setFetchingSentinel(false);
            cancelFeatures('Update Sentinel Features Filter');
            if (
                filterProvider === 'SENTINEL' &&
                !SatelliteUtil.sentinelQualityEvaluation(aoi).find((sentinelDimension) => sentinelDimension.isAvailable)
            ) {
                setSentinelError(
                    'No available Sentinel Feed on current zoom level. Please zoom in to see satellite feeds.'
                );
            } else if (filterProvider === 'LANDSAT' && !SatelliteUtil.landsatQualityEvaluation(aoi)[0].isAvailable) {
                setSentinelError(
                    'No available Landsat Feed on current zoom level. Please zoom in to see satellite feeds.'
                );
            } else {
                setFetchingSentinel(true);
                const layer = filterProvider === 'LANDSAT' ? Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8 : filterLayer;
                getFeatures(aoi, filterDate.startDate, filterDate.endDate, layer)
                    .then((res) => {
                        const items: CarouselItem[] = res.map((t) => {
                            return {
                                previewUrl: t.previewUrl,
                                title: `${t.layer.replace(/_/im, ' ')} ${new Date(t.date).toLocaleDateString()}`,
                                author: t.satellite,
                                satelliteFeature: t,
                                opacity: 1,
                            };
                        });
                        setSentinelFeatures(items);
                    })
                    .catch((e) => setSentinelError(e))
                    .finally(() => setFetchingSentinel(false));
            }
        }
    }, [aoi, filterDate.startDate, filterDate.endDate, filterLayer, filterProvider]);

    return (
        <SegmentContainer>
            <SegmentHeader>
                <SegmentTitle>
                    <SegmentTitleIcon src="/assets/dashboard/satellite_yellow.svg" />
                    Latest free satellite imagery in <span>this area</span>
                </SegmentTitle>
                <SegmentFilters>
                    <SatelliteSelect>
                        <Select
                            title="Filter by Provider"
                            value={filterProvider}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setFilterProvider(e.target.value as ProviderFilter);
                                Analytics.Event('Draw Tools - Project', `Filter By Provider ${e.target.value}`);
                            }}
                            disabled={fetchingSentinel}
                        >
                            <SelectOption value="SENTINEL">Sentinel</SelectOption>
                            <SelectOption value="LANDSAT">Landsat</SelectOption>
                        </Select>
                    </SatelliteSelect>

                    {filterProvider !== 'LANDSAT' ? (
                        <LayerSelect>
                            <Select
                                title="Filter by Layers"
                                value={filterLayer.key}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    handleSelectLayer(e.target.value);
                                    Analytics.Event(
                                        'Draw Tools - Project',
                                        `Filter Sentinel By Layer ${e.target.value}`
                                    );
                                }}
                                disabled={fetchingSentinel}
                            >
                                <SelectOption value="TRUE_COLOR">True Color</SelectOption>
                                <SelectOption value="FALSE_COLOR">False Color</SelectOption>
                                <SelectOption value="GEOLOGY">Geology</SelectOption>
                                <SelectOption value="NDVI">Vegetation Health</SelectOption>
                                <SelectOption value="SENTINEL_1_RADAR">Radar</SelectOption>
                            </Select>
                        </LayerSelect>
                    ) : null}
                    <DateSelect>
                        <Select
                            title="Filter by Date"
                            value={filterDate.displayText}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setFilterDate(
                                    dateFilterOptions.find((dateFilter) => dateFilter.displayText === e.target.value) ??
                                        last30Days
                                );
                                Analytics.Event('Draw Tools - Project', `Filter By Date ${e.target.value}`);
                            }}
                            disabled={fetchingSentinel}
                        >
                            {dateFilterOptions.map((option) => (
                                <SelectOption value={option.displayText}>{option.displayText}</SelectOption>
                            ))}
                        </Select>
                    </DateSelect>
                </SegmentFilters>
            </SegmentHeader>

            <SegmentContent>
                {fetchingSentinel ? <PulseLoader /> : null}
                {sentinelError ? <Error>{sentinelError}</Error> : null}
                {sentinelFeatures && sentinelFeatures.length > 0 ? (
                    <CarouselContainer>
                        <SearchContentCarousel
                            items={sentinelFeatures}
                            height="180px"
                            handleSelectItem={(item: CarouselItem) => props.onSelectItem(item)}
                        />
                    </CarouselContainer>
                ) : null}
            </SegmentContent>
        </SegmentContainer>
    );
};

export default SearchContentSentinelInViewport;

const CarouselContainer = styled.div``;

const Error = styled.p`
    color: ${(props) => props.theme.color.opaqueWhite};
    font-style: italic;
    text-align: center;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
