import { AppState } from '../root-reducer';

export const selectMyProfile = (state: AppState) => state.accountDomain.myProfile;

export const selectMyProfileReloading = (state: AppState) => state.accountDomain.reloadingProfile;

export const selectLoggedIn = (state: AppState) => state.accountDomain.loggedIn;

export const selectMyListings = (state: AppState) => state.accountDomain.myListings;

export const selectMyPoints = (state: AppState) => state.accountDomain.myPoints;

export const selectMyMapsLoading = (state: AppState) => state.accountDomain.myMapsLoading;
