import { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AutocompleteDTO } from '../../../api/model';
import { selectIsLocationSearching } from '../../../store/Search/selectors';
import { useFlyTo } from '../../../lib/use-fly-to';
import GeoUtil from '../../../lib/geo-util';
import { PulseLoader } from '../../Shared/pulse-loader';
import LocationSearchInput from './location-search-input';
import LocationSearchResults from './location-search-results';

const LocationSearch = () => {
    const isLocationSearching = useSelector(selectIsLocationSearching);
    const [searchResults, setSearchResults] = useState<AutocompleteDTO[]>([]);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const flyTo = useFlyTo();

    const handleSelectAutocomplete = (autocomplete: AutocompleteDTO) => {
        if (autocomplete.type === 'ADDRESS' && autocomplete.geometryWKT) {
            const latlngBounds = GeoUtil.latLngFromWKT(autocomplete.geometryWKT);
            flyTo(latlngBounds, true);
        } else if (autocomplete.type === 'COORDINATE' && autocomplete.latlng) {
            const bounds = autocomplete.latlng.toBounds(5000);
            flyTo(bounds, true);
        }
    };

    return (
        <LocationSearchContainer showInput={isLocationSearching} className={searchIsLoading ? 'search-loading' : ''}>
            <LocationSearchInput handleResults={setSearchResults} handleOnLoading={setSearchIsLoading} />
            {searchIsLoading ? (
                <PulseLoader />
            ) : (
                <LocationSearchResults searchResults={searchResults} onSelectAutocomplete={handleSelectAutocomplete} />
            )}
        </LocationSearchContainer>
    );
};

export default LocationSearch;

const LocationSearchContainer = styled.div<{ showInput: boolean }>`
    z-index: ${({ theme, showInput }) => theme.zIndex.drawer + (showInput ? 5 : -1)};
    background-color: rgba(0, 0, 0, 0.8);
    right: 15px;
    top: 215px;
    border-radius: 6px 0 6px 6px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: ${({ showInput }) => (showInput ? '350px' : '0')};
    opacity: ${({ showInput }) => (showInput ? 1 : 0)};
    transition: width 0.3s, opacity 0.4s ease-in-out;

    &.search-loading {
        border: 1px solid #515151;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`;
