import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import ApiAutocomplete from '../../../../../api/api-autocomplete';
import ApiListings from '../../../../../api/api-listings';
import Analytics from '../../../../../lib/user-analytics';
import { actionUpdateActiveSearchPosition } from '../../../../../store/Search/actions';
import {
    actionSetSelectedIsSearchActive,
    actionSetSelectedSearchTerm,
} from '../../../../../store/Map/MapSelection/actions';

const DEBOUNCE_TIME = 300; //ms

interface MapSearchDrawInputProps {
    toggleDrawer: () => void;
}

const MapSearchDrawInput = ({ toggleDrawer }: MapSearchDrawInputProps) => {
    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchSubject] = useState(() => new Subject<string>());
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        dispatch(actionSetSelectedSearchTerm(debouncedSearchInput));
        if (debouncedSearchInput.length > 0) {
            Analytics.Event('Side Drawer', 'Debounced Map Search', debouncedSearchInput);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput]);

    useEffect(() => {
        searchSubject.next(searchInput);

        if (searchInput?.length) {
            dispatch(actionSetSelectedIsSearchActive(true));
        } else {
            dispatch(actionSetSelectedIsSearchActive(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap(() => {
                    setIsLoading(true);
                    ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
                    ApiListings.cancelGetListings('Cancel get listings');
                    ApiListings.cancelAOIListing('Cancel AOI listings');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
                setTimeout(() => {
                    setIsLoading(false);
                }, DEBOUNCE_TIME); // This is to prevent the loading icon from flickering
            });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchInputContainer id="search-input" isMounted={isMounted}>
            <SearchInputItem>
                <SearchIcon src="/assets/side-drawer/discover_search_icon.svg" />
                <Input
                    onFocus={() => toggleDrawer()}
                    ref={searchInputRef}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    type="text"
                    placeholder={"Search Soar's digital atlas..."}
                />

                {searchInput.length > 0 ? (
                    <CloseIconContainer>
                        {isLoading ? (
                            <LoadingIcon className="fas fa-spinner fa-spin" />
                        ) : (
                            <CloseIcon
                                onClick={() => {
                                    setSearchInput('');
                                    dispatch(actionUpdateActiveSearchPosition(undefined));
                                    Analytics.Event('Side Drawer', 'Search Cleared');
                                }}
                                src="/assets/close.png"
                            />
                        )}
                    </CloseIconContainer>
                ) : null}
            </SearchInputItem>
        </SearchInputContainer>
    );
};

export default MapSearchDrawInput;

const SearchInputContainer = styled.div<{ isMounted: boolean }>`
    position: absolute;
    z-index: 99999;
    width: ${({ isMounted }) => (isMounted ? 'calc(100vw / 3)' : '40px')};
    max-width: ${({ isMounted }) => (isMounted ? '650px' : '0px')};
    height: 40px;
    left: 190px;
    top: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    border: 1px solid rgb(81, 81, 81);
    transition: width 0.3s ease-in-out;
`;

const SearchInputItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const SearchIcon = styled.img`
    height: 35px;
    width: auto;
    margin: 0px 1px;
`;

const Input = styled.input`
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: white;
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 40px;
    font-weight: 200;

    &::placeholder {
        color: ${(props) => props.theme.color.lightGray};
    }
`;

const CloseIconContainer = styled.div`
    position: absolute;
    right: 5px;
    height: 20px;
    width: 20px;
    top: calc(50% - 11px);
    cursor: pointer;
`;

const CloseIcon = styled.img`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    cursor: pointer;
    vertical-align: bottom;
`;

const LoadingIcon = styled.i`
    color: white;
    font-size: 19px;
    margin: 0;
    padding: 0;
    margin-top: 2px;
    margin-right: 2px;
`;
