import React from 'react';
import SoarHelper, { isMobileVersion } from '../../lib/soar-helper';
import styled from 'styled-components';
import { ListingGroup } from '../../store/Map/MapSelection/model';
import SearchResultsBreadcrumbs from './search-results-breadcrumbs';

interface SearchResultsEmptyViewProps {
    isLoading: boolean;
    continent?: ListingGroup;
    term?: string;
}

// TODO mark for removal?
const SearchResultsEmptyView = (props: SearchResultsEmptyViewProps) => {
    if (props.isLoading && (!props.continent || !props.term)) {
        return <React.Fragment />;
    }

    return (
        <EmptySearchView>
            {!SoarHelper.isSoar() ? (
                <>
                    <SearchIcon className="fa fa-search" />
                    <EmptySearchText>
                        There are no maps to display on your subdomain.
                        {isMobileVersion && (
                            <EmptySearchHelpText>You can upload maps using a desktop computer.</EmptySearchHelpText>
                        )}
                    </EmptySearchText>
                </>
            ) : (
                <>
                    <SearchIcon className="fa fa-search" />
                    <EmptySearchText>
                        We searched the community maps for <SearchResultsBreadcrumbs /> but found no results.
                        <EmptySearchHelpText>
                            Try changing the search to see what else you can find.
                        </EmptySearchHelpText>
                    </EmptySearchText>
                </>
            )}
        </EmptySearchView>
    );
};

export default SearchResultsEmptyView;

const EmptySearchView = styled.div`
    color: white;
    width: 55%;
    height: 50%;
    position: relative;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;

const SearchIcon = styled.i`
    color: #eed926;
    font-size: 74px;
    position: absolute;
    left: -15px;
    top: -15px;
    border: 3px solid #eed926;
    padding: 10px;
    z-index: 99999;
    border-radius: 8px;
    background: #191a1a;
    box-shadow: 0px 11px 14px -7px rgb(0 0 0 / 30%), 0px 23px 36px 3px rgb(0 0 0 / 24%),
        0px 9px 44px 8px rgb(0 0 0 / 22%);

    @media only screen and (max-width: 600px) {
        border: 2px solid #eed926;
        margin-bottom: -50px;
        position: initial;
    }
`;

const EmptySearchText = styled.div`
    color: white;
    margin: 25px;
    font-size: 18px;
    border: 2px solid #eed926;
    padding: 50px 35px 55px 70px;
    border-radius: 8px;
    background: #191a1a;
    text-align: left;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
        margin: auto;
        padding: 60px 30px 60px 30px;
        text-align: center;
    }
`;

const EmptySearchHelpText = styled.div`
    margin-top: 20px;
    color: white;
    font-size: 14px;
`;
