import React from 'react';
import styled from 'styled-components';
import { lightDropShadow } from '../../../../styles/style';

interface ThinDrawerProps {
    id?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

/**
 * ThinDrawer
 * Wrapper that applies a standardized thin width to the drawer
 * @required children React.ReactNode
 */
// TODO peg for removal
const ThinDrawer = (props: ThinDrawerProps) => {
    return (
        <ThinDrawerContainer id={props.id} style={props.style}>
            {props.children}
        </ThinDrawerContainer>
    );
};

export default ThinDrawer;

const ThinDrawerContainer = styled.div`
    position: absolute;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    left: 16px;
    top: 94px;
    max-width: 286px;
    min-width: 200px;

    ${lightDropShadow};
`;
