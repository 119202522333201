import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TruncatedText } from '../../Shared/truncated-text';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectResponse } from '../../../api/api-draw';

import { deleteAllAnnotationsAction } from '../../../store/Annotations/actions';
import { actionResetDrawProject, actionResetDrawStoryProject } from '../../../store/Map/DrawProject/actions';
import {
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';

import DrawProjectShare from '../../Dashboard/DrawProjects/draw-project-share';
import { StyledButton } from '../../Shared/styled-button';
import OpacitySlider from '../Maps/ActiveMap/active-map-opacity-slider';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { selectStoryProjectNumber } from '../../../store/Map/DrawProject/selectors';

interface ActiveProjectDrawerProps {
    drawProjectArray: ProjectResponse[];
    handleBack: () => void;
}

const MAX_DESCRIPTION_LENGTH = 5000;

// Stripped version of the project drawer, removes author (as possibly multiple could have created the projects), date and text truncating
const ActiveStoryMapProjectDrawer = ({ drawProjectArray, handleBack }: ActiveProjectDrawerProps) => {
    const activeMap = useSelector(selectActiveAllMap);
    const projectNumber = useSelector(selectStoryProjectNumber);
    const drawProject = drawProjectArray[projectNumber || 0];

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            const resetDrawProject = () => {
                dispatch(actionResetDrawProject());
                dispatch(deleteAllAnnotationsAction());
                dispatch(actionSentinelResetSelectedFeature());
                dispatch(actionSentinelResetSelectedAOI());
                dispatch(actionActiveMapCleared());
                dispatch(actionResetDrawStoryProject());
            };
            // Draw is no longer mounted so we do a full reset of the project
            const drawPath = '/draw/';
            const isDrawPath = UriHelper.isPathMatchingPattern(drawPath);
            if (!isDrawPath) {
                resetDrawProject();
            }
        };
    }, [dispatch]);

    if (!drawProjectArray[0]) {
        return <React.Fragment />;
    }

    return (
        <DrawProjectContainer>
            <StyledDrawerHeader>
                <Title title={drawProject.title || 'Untitled Project'}>{drawProject.title || 'Untitled Project'}</Title>
            </StyledDrawerHeader>
            <Content>
                {drawProject.description ? (
                    <TruncatedText text={drawProject.description} length={MAX_DESCRIPTION_LENGTH} />
                ) : null}
            </Content>
            <DrawerFooter>
                {activeMap && (
                    <DrawerLayerOpacity>
                        <OpacitySlider tileLayer={activeMap} />
                    </DrawerLayerOpacity>
                )}
                <DrawerShareContainer>
                    <DrawProjectShareIcons>
                        <DrawProjectShare />
                    </DrawProjectShareIcons>
                    <CloseProjectButton
                        onClick={() => {
                            Analytics.Event('Draw Tools - Project', 'Closed viewing project', {
                                ownerId: drawProject.ownerId,
                                projectId: drawProject.id,
                            });
                            handleBack();
                        }}
                    >
                        Close
                    </CloseProjectButton>
                </DrawerShareContainer>
            </DrawerFooter>
        </DrawProjectContainer>
    );
};

export default ActiveStoryMapProjectDrawer;

const DrawProjectContainer = styled.div`
    min-height: 0px;
`;

const StyledDrawerHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
`;

const Title = styled.h1`
    flex: 0 1 auto;
    margin: 0 12px;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 30px;
    max-width: 440px;
    -webkit-line-clamp: 5;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    line-height: 3rem;
    word-break: break-word;
    hyphens: auto;
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin: 12px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    word-break: break-word;
    min-height: 30px;
`;

const DrawerFooter = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 10px 10px;
    background: rgb(255, 255, 255, 5%);
    width: 100%;
`;

const DrawerLayerOpacity = styled.div`
    flex: 1;
`;

const DrawerShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    align-items: center;

    i {
        margin: 0;
    }
`;

const CloseProjectButton = styled(StyledButton)`
    padding: 4px 8px;
`;

const DrawProjectShareIcons = styled.div`
    width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
