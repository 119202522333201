import { useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import ListingHelper from '../../../../lib/listing-helper';
import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';
import TemporalCategoryControl from '../../Shared/TemporalCategory/temporal-category-control';
import ImageWithFallback from '../../Shared/image-with-fallback';

interface DiscoverCardProps {
    listing: ListingDTO;
}

const DiscoverCardGridView = ({ listing }: DiscoverCardProps) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleSelectedListing = (listingId: number) => {
        Analytics.Event('Discover', 'Selected Listing', listingId);
        UriHelper.navigateToPath(`/maps/${listingId}`);
    };

    return (
        <DiscoverCardContainer className={imageLoaded ? 'fade-in' : ''}>
            <ImageContainer onClick={(e) => e.preventDefault()}>
                <ImgContainer onClick={() => handleSelectedListing(listing.id)}>
                    <DiscoverCardImage
                        src={ListingHelper.getPreviewUrlForListing(listing.id, 'small')}
                        alt={listing.title || 'Soar Image'}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setImageLoaded(true)}
                        loading="lazy"
                    />
                    <ImgContainerOverlay>
                        <TemporalCategoryControl listing={listing} margin="5px 0px 0px 0px" showTemporalCategories />
                    </ImgContainerOverlay>
                </ImgContainer>
                <ImgDetails onClick={() => handleSelectedListing(listing.id)} data-testid="handle-image-select">
                    <ImgTitle>{listing.title}</ImgTitle>
                    <ImgAuthor>{listing.userName || 'Soar User'}</ImgAuthor>
                </ImgDetails>
            </ImageContainer>
        </DiscoverCardContainer>
    );
};

export default DiscoverCardGridView;

const ImageContainer = styled.a`
    display: block;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 16px;
`;

const ImgContainer = styled.div`
    margin: 0;
    position: relative;
    vertical-align: middle;
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.03);
    }
`;

const ImgContainerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background: transparent;
    color: #ffffff;

    > div {
        justify-content: flex-end;
    }
`;

const ImgDetails = styled.div`
    color: ${(props) => props.theme.color.white};
    display: flex;
    flex-direction: column;
`;

const ImgTitle = styled.h3`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(1rem, 1.2rem, 1.2rem);
    color: ${(props) => props.theme.color.yellow};
    text-align: left;
    word-break: break-word;
    max-width: 100%;
    margin: 8px 0;
    color: ${(props) => props.theme.color.white};

    ${ImageContainer}:hover & {
        transition: all 0.3s ease-in-out;
        opacity: 1;
    }
`;

const ImgAuthor = styled.p`
    color: ${(props) => props.theme.color.yellow};
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before {
        content: 'by ';
        color: ${(props) => props.theme.color.white};
        font-size: 0.8rem;
        font-weight: normal;
        margin-right: 4px;
    }
`;

const DiscoverCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    opacity: 0;
    height: 120px;
    overflow: hidden;
    transition: opacity 0.3s, height 0.3s;
    padding: 8px;
    cursor: pointer;

    &.fade-in {
        margin: 6px 3px;
        opacity: 1;
        height: auto;
    }
`;

const DiscoverCardImage = styled(ImageWithFallback)`
    height: auto;
    max-width: 100%;
    object-fit: contain;
    background-color: hsla(0, 100%, 100%, 0.02);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    transition: transform 0.3s ease-in-out;
    border-radius: 16px;

    ${ImgContainer}:hover & {
        transform: scale(1.03);
    }
`;
