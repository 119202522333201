import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { selectSideDrawerMode } from '../../store/SideDrawer/selectors';
import { SideDrawerMode } from '../../store/SideDrawer/model';

interface SearchContainerProps {
    hasFocus: boolean;
    children: React.ReactElement;
    onClose: () => void;
}

const OPEN_ANIMATION_TIME = 300; //ms
const OPEN_ANIMATION_DELAY = 100; //ms
const CLOSE_ANIMATION_TIME = 200; //ms

const SearchContainer = (props: SearchContainerProps) => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    const [containerClassName, setContainerClassName] = useState<'opening' | 'open' | 'closing' | 'closed'>('closed');

    const closeSearch = (onClose?) => {
        setContainerClassName('closing');
        setTimeout(() => {
            setContainerClassName('closed');
            if (onClose) {
                onClose();
            }
        }, CLOSE_ANIMATION_TIME);
    };

    const openSearch = () => {
        setContainerClassName('opening');
        setTimeout(() => setContainerClassName('open'), OPEN_ANIMATION_TIME);
    };

    useEffect(() => {
        if (sideDrawerMode === SideDrawerMode.SHARE_MAP && containerClassName !== 'closed') {
            closeSearch(() => {
                props.onClose();
            });
        } else {
            if (props.hasFocus && containerClassName === 'closed') {
                openSearch();
            } else if (!props.hasFocus && containerClassName === 'open') {
                closeSearch();
            }
        }
    }, [containerClassName, props, sideDrawerMode]);

    const isOpen = containerClassName === 'open';

    return (
        <Container className={containerClassName}>
            {isOpen ? (
                <React.Fragment>
                    <ContainerBorderRemove />
                    <CloseIcon
                        src="/assets/close.png"
                        onClick={() => props.onClose()}
                        data-testid="search-modal-close-button"
                    />
                    {props.children}
                </React.Fragment>
            ) : null}
        </Container>
    );
};

export default SearchContainer;

const openAnimation = keyframes`
    from {
        min-height: 0;
        width: 40vw;
        left: 35vw;
    }
    to {
        min-height: 75vh;
        width: 74vw;
        left: 17vw;
    }
`;

const closeAnimation = keyframes`
    from {
        min-height: 75vh;
        width: 74vw;
        left: 17vw;

    }
    to {
        min-height: 0vh;
        width: 40vw;
        left: 35vw;
    }
`;

const CloseIcon = styled.img`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin: 10px 8px 0 0;
    cursor: pointer;
    float: right;
`;

const Container = styled.div`
    width: 74vw;
    margin: 0 auto;
    padding: 0;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    transition: min-height ${OPEN_ANIMATION_TIME}ms ease-in-out 0ms;
    border-radius: 6px;
    position: fixed;
    top: 136px;
    left: calc((100vw - 74vw) / 2);
    z-index: 10000;
    min-height: 0;
    max-height: 75vh;
    overflow-y: scroll;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #eed926;
        border-radius: 4px;
    }

    &.opening {
        animation: ${openAnimation} ${OPEN_ANIMATION_TIME}ms forwards;
        animation-delay: ${OPEN_ANIMATION_DELAY}ms;
    }

    &.open {
        min-height: 75vh;
        -webkit-box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151;
    }

    &.closing {
        animation: ${closeAnimation} ${CLOSE_ANIMATION_TIME}ms forwards;
        min-height: 0;
    }

    &.closed {
        min-height: 0;
        display: none;
    }
`;

const ContainerBorderRemove = styled.div`
    margin: 0 auto;
    padding: 0;
    display: block;
    background: transparent;
    transition: min-height ${OPEN_ANIMATION_TIME}ms ease-in-out 0ms;
    border-radius: 6px;
    position: fixed;
    top: 140px;
    width: calc(40vw - 2px);
    left: calc(35vw + 1px);
    height: 5px;
    z-index: 10001;
`;

export const SegmentContainer = styled.div`
    margin: 0px 6px 10px 6px;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);

    &.unstyled {
        margin: 0px;
        padding: 0px;
        border: none;
        background: transparent;
    }
`;

export const SegmentTitle = styled.h4`
    color: white;
    text-align: left;
    font-size: 1.6rem;
    margin: 10px 0px;

    span {
        color: #eed923;
    }
`;

export const SegmentTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    margin: -4px 8px 0 8px;
`;
