import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';
import ApiDraw from '../../../../api/api-draw';

const DEBOUNCE_TIME = 300; //ms

interface SideDrawerStoarySearchProps {
    setSearchWord: (searchWord: string) => void;
}

const SideDrawerStoarySearch = ({ setSearchWord }: SideDrawerStoarySearchProps) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchSubject] = useState(() => new Subject<string>());

    useEffect(() => {
        setSearchWord(debouncedSearchInput);
    }, [debouncedSearchInput, setSearchWord]);

    useEffect(() => {
        searchSubject.next(searchInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap(() => {
                    setIsLoading(true);
                    ApiDraw.cancelSearchDrawProjects('User Updated Stoaries Search Term');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
                setTimeout(() => {
                    setIsLoading(false);
                }, DEBOUNCE_TIME); // This is to prevent the loading icon from flickering
            });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchContainer>
            <SearchIcon className="fa fa-search" />
            <SearchInput
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                type="text"
                placeholder={'Search Public Stoaries...'}
            />

            {searchInput.length > 0 ? (
                <CloseIconContainer>
                    {isLoading ? (
                        <LoadingIcon className="fas fa-spinner fa-spin" />
                    ) : (
                        <CloseIcon
                            onClick={() => {
                                setSearchInput('');
                                Analytics.Event('Side Drawer', 'Stoaries Search Cleared');
                            }}
                            src="/assets/close.png"
                        />
                    )}
                </CloseIconContainer>
            ) : null}
        </SearchContainer>
    );
};

export default SideDrawerStoarySearch;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 97%;
    height: 40px;
    margin: 10px 0px 5px 5px;
`;

const SearchIcon = styled.i`
    padding: 10px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 3px 30px;
    background: transparent;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    height: 35px;

    :focus {
        outline: none;
    }
`;

const CloseIconContainer = styled.div`
    position: absolute;
    right: 5px;
    height: 20px;
    width: 20px;
    top: calc(50% - 11px);
    cursor: pointer;
`;

const CloseIcon = styled.img`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    cursor: pointer;
    vertical-align: bottom;
`;

const LoadingIcon = styled.i`
    color: white;
    font-size: 19px;
    margin: 0;
    padding: 0;
    margin-top: 2px;
    margin-right: 2px;
`;
