import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalBody, Spinner } from 'reactstrap';
import styled from 'styled-components';
import ShareService from '../../../lib/share-service';
import Analytics from '../../../lib/user-analytics';
import { actionSentinelCloseShare } from '../../../store/Map/Sentinel/actions';
import { selectIsSentinelShareOpen, selectSentinelSelectedFeature } from '../../../store/Map/Sentinel/selectors';
import SoarModal from '../../Shared/soar-modal';
import ImageWithFallback from '../Shared/image-with-fallback';

const SentinelShareModal = () => {
    const dispatch = useDispatch();
    const isOpenProps = useSelector(selectIsSentinelShareOpen);
    const sentinelFeature = useSelector(selectSentinelSelectedFeature);

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [shareURI, setShareURI] = useState('');
    const [isGeneratingLink, setIsGeneratingLink] = useState<boolean>(false);
    const [shareURIError, setShareURIError] = useState<string | undefined>();

    useEffect(() => {
        if (sentinelFeature && isOpenProps) {
            setIsGeneratingLink(true);
            ShareService.shareSentinelFeatureUrl(sentinelFeature)
                .then((miniShareURI) => {
                    setShareURI(miniShareURI);
                    setIsGeneratingLink(false);
                    setShareURIError(undefined);
                })
                .catch(() => {
                    setShareURIError('Failed to generate share links');
                    setIsGeneratingLink(false);
                });
        }
    }, [isOpenProps, sentinelFeature]);

    const handleClickShareToTwitter = () => {
        const shareLink = 'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=' + shareURI;
        Analytics.Event('Social Media - Clicked to share', 'Twitter', shareLink);
        window.open(shareLink, '_blank');
    };

    const handleClickShareToFacebook = () => {
        const shareLink = 'https://www.facebook.com/sharer.php?u=' + shareURI;
        Analytics.Event('Social Media - Clicked to share', 'Facebook', shareLink);
        window.open(shareLink, '_blank');
    };

    const handleClickShareToLinkedin = () => {
        const shareLink = 'https://www.linkedin.com/shareArticle?mini=true&url=' + shareURI;
        Analytics.Event('Social Media - Clicked to share', 'LinkedIn', shareLink);
        window.open(shareLink, '_blank');
    };

    useEffect(() => {
        setIsOpen(isOpenProps);
    }, [isOpenProps]);

    const handleCopy = () => {
        if (shareURI) {
            const textField = document.createElement('textarea');
            textField.innerText = decodeURIComponent(shareURI);
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            toast.dark('Copied to clipboard');
            Analytics.Event('Social Media - Clicked to share', 'Link', shareURI);
        }
    };

    const handleCanClose = () => {
        if (!isGeneratingLink) {
            dispatch(actionSentinelCloseShare());
        }
    };

    return (
        <React.Fragment>
            {isOpen && (
                <SoarModal title={'Share'} isOpen={isOpen} toggle={() => handleCanClose()}>
                    {sentinelFeature && (
                        <SharePreview>
                            <SharePreviewImage
                                src={sentinelFeature.sharelinkPreviewUrl}
                                alt="Sentinel Share Preview"
                                height="150px"
                            />
                        </SharePreview>
                    )}
                    {isGeneratingLink ? (
                        <React.Fragment>
                            <ShareModalBody>
                                <ShareBodyTitle>Share this with your network</ShareBodyTitle>
                                <ShareLinkGeneratingSpinnerContainer>
                                    <ShareLinkGeneratingSpinner />
                                </ShareLinkGeneratingSpinnerContainer>
                            </ShareModalBody>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {shareURIError ? (
                                <ShareLinkError>{shareURIError}</ShareLinkError>
                            ) : (
                                <>
                                    <ShareModalBody>
                                        <ShareBodyTitle>Share this with your network</ShareBodyTitle>
                                        <ShareContainer>
                                            <SocialIcons>
                                                <XShareLinkIcon
                                                    data-testid="share-icon-x"
                                                    onClick={handleClickShareToTwitter}
                                                />
                                                <ShareIcon
                                                    data-testid="share-icon-fb"
                                                    className="fa fa-facebook"
                                                    onClick={handleClickShareToFacebook}
                                                />
                                                <ShareIcon
                                                    data-testid="share-icon-ln"
                                                    className="fa fa-linkedin"
                                                    onClick={handleClickShareToLinkedin}
                                                />
                                            </SocialIcons>
                                            <ShareLinkContainer
                                                data-testid="share-icon-link"
                                                className={shareURI ? '' : 'no-link'}
                                                onClick={handleCopy}
                                            >
                                                <ShareLinkLabel>{shareURI}</ShareLinkLabel>
                                                <ShareLinkIcon src="/assets/floating-drawer-satellite-icons/share-icons/copy.png" />
                                            </ShareLinkContainer>
                                        </ShareContainer>
                                    </ShareModalBody>
                                </>
                            )}
                        </React.Fragment>
                    )}
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default SentinelShareModal;

const ShareModalBody = styled(ModalBody)`
    border-top: none;
    border-bottom: none;
    padding: 0px 20px;
`;

const ShareBodyTitle = styled.div`
    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-bottom: 20px;

    @media (max-width: 650px) {
        display: none;
    }
`;

const ShareContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
`;

const ShareIcon = styled.i`
    color: white;
    font-size: 18px;
    margin: 0px 0px 0px 16px;
    pointer-events: all;
    cursor: pointer;

    &:hover {
        color: #eed926;
    }
`;

const SharePreview = styled.div`
    display: flex;
    padding: 20px;
    border-top: none;
    border-bottom: none;
    border-radius: 6px;

    @media (max-width: 650px) {
        display: none;
    }
`;

const SharePreviewImage = styled(ImageWithFallback)`
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 150px;
    border-radius: 6px !important;
`;

const ShareLinkContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 80%;

    &.no-link {
        cursor: wait !important;
        border: 1px solid rgb(255 255 255 / 0.3);
        border-right: none;
        padding: 8px 0px 8px 5px;
        border-radius: 6px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
`;

const ShareLinkLabel = styled.div`
    background: linear-gradient(90.02deg, rgba(255, 255, 255, 0.6) 75.23%, rgba(255, 255, 255, 0) 96.41%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding: 7px;
    margin-right: -10px;
    border: 1px solid rgb(255 255 255 / 0.3);
    border-right: none;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
`;

const ShareLinkIcon = styled.img`
    background: #eed926;
    padding: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
`;

const ShareLinkGeneratingSpinnerContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const ShareLinkGeneratingSpinner = styled(Spinner)`
    color: #eed926;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
`;

const ShareLinkError = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgb(255 255 255 / 0.3);
    border-top: none;
    border-bottom: none;
    padding: 20px 20px;
    color: red;
    font-size: 15px;
    text-align: center;
`;

const XShareLinkIcon = styled.div`
    height: 16px;
    width: 16px;
    margin-top: 2px;
    pointer-events: all;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E");

    &:hover {
        color: #eed926;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23EED926;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E");
    }
`;
