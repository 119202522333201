import { ListingDTO, UserDTO } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

interface MyAccount {
    loggedIn: boolean;
    reloadingProfile: boolean;
    myProfile?: UserDTO;
    myListings?: ListingDTO[] | undefined;
    myPoints?: number;
    myMapsLoading?: boolean;
}

const initialState: MyAccount = {
    loggedIn: false,
    reloadingProfile: false,
    myProfile: undefined,
    myListings: undefined,
    myPoints: 0,
    myMapsLoading: false,
};

export default (state: MyAccount = initialState, action: Action): MyAccount => {
    switch (action.type) {
        case at.GET_MY_PROFILE_SUCCESS:
            return { ...state, myProfile: action.payload, myPoints: action.payload.points, loggedIn: true };
        case at.GET_MY_LISTINGS_SUCCESS:
            return { ...state, myListings: action.payload };
        case at.GET_MY_POINTS_SUCCESS:
            return {
                ...state,
                myPoints: action.payload,
            };
        case at.LOGOUT_USER:
            return { ...state, myProfile: undefined, loggedIn: false };
        case at.MY_PROFILE_RELOADING:
            return { ...state, reloadingProfile: action.payload };
        case at.FETCH_MY_MAPS_ISLOADING:
            return { ...state, myMapsLoading: action.payload };
        default:
            return { ...state };
    }
};
