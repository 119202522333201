import { LoadMoreItemsCallback, Positioner, useMasonry } from 'masonic';
import React from 'react';
import SearchResultsMasonaryCard from './search-results-masonary-card';
import { ListingType } from '../../api/model';

export interface SelectableListing {
    id: number;
    authorName: string;
    title: string;
    handleSelectListing: (id: number, listingData?: SelectableListing) => void;
    createdAt: number;
    popularity: number;
    listingType: ListingType;
    categories: string[];
}

interface SearchResultsMasonaryProps {
    positioner: Positioner;
    resizeObserver: ResizeObserver;
    maybeLoadMore: LoadMoreItemsCallback<unknown>;
    height: number;
    scrollTop: number;
    isScrolling: boolean;
    items: SelectableListing[];
    handleSelectListing: (id: number, listingData?: SelectableListing) => void;
}

/**
 * The <Masonry> component from 'masonic' doesn't like to be inside of scrolling div
 * so we need to use the `useMasonry` hook.  However to invalidate the results when
 * a new search term is entered we need to conditionally render it, which violates the
 * rules of hooks.  To overcome this we pass in all the props to a separate component.
 */
const SearchResultsMasonary = (props: SearchResultsMasonaryProps) => {
    const selectableListings: SelectableListing[] = props.items
        .map((t) => {
            return {
                id: t.id,
                authorName: t.authorName,
                title: t.title,
                handleSelectListing: props.handleSelectListing,
                createdAt: t.createdAt,
                popularity: t.popularity,
                listingType: t.listingType,
                categories: t.categories,
            };
        })
        .filter((value, index, array) => {
            return array.indexOf(value) === index;
        });

    return (
        <React.Fragment>
            {useMasonry<SelectableListing>({
                positioner: props.positioner,
                resizeObserver: props.resizeObserver,
                items: selectableListings,
                itemHeightEstimate: 400,
                height: props.height,
                scrollTop: props.scrollTop,
                isScrolling: props.isScrolling,
                overscanBy: 2,
                itemKey: (data) => {
                    return data.id;
                },
                render: SearchResultsMasonaryCard,
                onRender: props.maybeLoadMore,
            })}
        </React.Fragment>
    );
};

export default SearchResultsMasonary;
