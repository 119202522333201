import { useState } from 'react';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import ImageryShare from '../Imagery/imagery-share';
import ImagePreviewFailed from '../../Shared/image-preview-failed';

interface BookmarkMasonaryCardProps {
    listing: ListingDTO;
    //cardWidth: number;
    dataTestId?: string;
}

const BookmarkMasonaryCard = (props: BookmarkMasonaryCardProps) => {
    const [imageFailed, setImageFailed] = useState(false);

    return (
        <BookmarkCard>
            <ImageryShare dataTestId={props.dataTestId && `${props.dataTestId}-share`} listing={props.listing} />
            {imageFailed ? (
                <BookmarkImagePreviewFailed />
            ) : (
                <BookmarkImage
                    data-testid={props.dataTestId && `${props.dataTestId}-image`}
                    src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                    onClick={() => {
                        UriHelper.navigateToMap(props.listing.id);
                        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.SEARCH, props.listing.id);
                    }}
                    onError={() => setImageFailed(true)}
                />
            )}
            <BookmarkCardDetails>
                <BookmarkTitle data-testid={props.dataTestId && `${props.dataTestId}-title`}>
                    {props.listing.title}
                </BookmarkTitle>
            </BookmarkCardDetails>
        </BookmarkCard>
    );
};

export default BookmarkMasonaryCard;

const BookmarkCard = styled.div`
    width: 100%;
    padding: 6px;
    position: relative;
`;

const BookmarkImagePreviewFailed = styled(ImagePreviewFailed)`
    height: 280px !important;

    &:hover {
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
        opacity: 0.5;
        cursor: pointer;
        width: 100%;
    }
`;

const BookmarkImage = styled.img`
    border: 1px solid transparent;
    background-color: rgb(255 255 255 / 2%);
    border-radius: 6px;
    width: inherit;

    &:hover {
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
        opacity: 0.5;
        cursor: pointer;
    }
`;

const BookmarkCardDetails = styled.div`
    background-color: rgb(0 0 0 / 45%);
    box-shadow: 0px -2px 2px 0px rgb(0 0 0 / 37%);
    margin: -25px 0px 0px 0px;
    position: absolute;
    width: calc(100% - 12px);
    border-radius: 0px 0px 6px 6px;
`;

const BookmarkTitle = styled.p`
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
