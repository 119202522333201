import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { actionSetResetCommentSection } from '../../../store/SocialMapping/actions';
import { MarkdownViewer } from '../../Shared/markdown-viewer';
import { BottomSheetState } from './bottom-sheet';
import Analytics from '../../../lib/user-analytics';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import ProfileName from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { DrawPage } from '../../MapView/Annotations/StoryMaps/draw-story';
import StoryMapAttribution from '../../MapView/Annotations/StoryMaps/StoryView/story-map-attribution';
import SentinelMapAttribution from '../../MapView/Annotations/StoryMaps/StoryView/sentinel-map-atribution';
import { selectSentinelSelectedFeature } from '../../../store/Map/Sentinel/selectors';

export interface StoryOwnerDetailsType {
    userId: string;
    userName: string;
    reputable: boolean;
}

interface ActiveMapBottomSheetProps {
    page: DrawPage;
    numberOfPages: number;
    owner: StoryOwnerDetailsType;
    bottomSheetState: BottomSheetState;
    onCloseMap: () => void;
    isClosing: boolean;
}

const ActiveStoryProjectBottomSheet = (props: ActiveMapBottomSheetProps) => {
    const { isClosing, onCloseMap } = props;

    const dispatch = useDispatch();

    const activeMap = useSelector(selectActiveAllMap);
    const sentinelFeature = useSelector(selectSentinelSelectedFeature);

    useEffect(() => {
        const closeActiveMap = () => {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
            dispatch(actionSetResetCommentSection(true));
            onCloseMap();
            Analytics.Event(
                'Mobile - Active Project Bottom Sheet',
                'Clicked Close Active Map Bottom Sheet',
                props.page?.id
            );
        };

        if (isClosing) {
            closeActiveMap();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosing, props.page?.id]);

    const handleViewProfile = () => {
        dispatch(actionActiveMapCleared());
        UriHelper.navigateToProfile(props.owner.userId);
    };

    const renderPageTitle = () => {
        if (props.page.projectTitle && props.page.pageTitle) {
            if (
                props.numberOfPages > 1 ||
                (props.numberOfPages === 1 && !/^Page [0-9]+( \(copy\))?$/i.test(props.page.pageTitle))
            ) {
                return <PageTitle>{props.page.pageTitle}</PageTitle>;
            }
        }
        return null;
    };

    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={props.bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <Title singleLine={props.bottomSheetState === BottomSheetState.close}>
                        {props.page.projectTitle || props.page.pageTitle || 'Soar Project'}
                    </Title>
                    <Author>
                        by&nbsp;
                        <UserProfileLink
                            userId={props.owner.userId}
                            onClick={handleViewProfile}
                            isReputable={props.owner.reputable}
                        >
                            {props.owner.userName}
                        </UserProfileLink>
                    </Author>
                </MapDetails>
            </TopContainer>
            {activeMap && <StoryMapAttribution listing={activeMap} />}
            {sentinelFeature && <SentinelMapAttribution feature={sentinelFeature} />}
            {props.page?.description || props.page?.pageTitle ? <Divider /> : null}
            {renderPageTitle()}
            {props.page.description ? (
                <DescriptionContainer>
                    <MarkdownViewer text={props.page.description} />
                </DescriptionContainer>
            ) : null}
        </React.Fragment>
    );
};

export default ActiveStoryProjectBottomSheet;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '100px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: calc(100% - 60px);
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 3rem;
    margin: 12px 0 0 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow-wrap: ${(props) => (props.singleLine ? 'unset' : 'break-word')};
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const DescriptionContainer = styled.div`
    margin: 12px 12px 0 12px;

    p {
        font-size: 16px;
        line-height: 21px;
        font-weight: 200;
    }
`;

const Author = styled.p`
    display: flex;
    text-overflow: ellipsis;
    width: calc(100%); /* The trick is here! */
    text-align: left;
    font-size: 16px;
    color: white;
    margin: 0 0 16px 12px;
    line-height: 21px;

    a {
        color: #eed926 !important;
        text-decoration: underline !important;
        pointer-events: all;
    }
`;

const UserProfileLink = styled(ProfileName)`
    display: flex;
    width: 100%;
    flex: 1;
    margin: 0 !important;
    font-size: inherit;

    a {
        display: block;
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
    }
`;

const PageTitle = styled.div`
    margin: 12px 12px 0px 12px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    overflow-wrap: break-word;
`;
