import styled from 'styled-components';
import { PulseLoader } from '../../../Shared/pulse-loader';

const SideDrawerLoader = () => {
    return (
        <LoaderContainer>
            <PulseLoader />
        </LoaderContainer>
    );
};

export default SideDrawerLoader;

const LoaderContainer = styled.div`
    height: 20vh;
`;
