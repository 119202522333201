import React from 'react';
import styled from 'styled-components';
import { lightDropShadow } from '../../../../styles/style';

interface DrawerProps {
    children: React.ReactNode;
}

/**
 * Drawer
 * Wrapper that applies a standardized wide width to the drawer
 * @required children React.ReactNode
 */
// TODO peg for removal
const Drawer = (props: DrawerProps) => {
    return <DrawerContainer>{props.children}</DrawerContainer>;
};

export default Drawer;

const DrawerContainer = styled.div`
    width: 30%;
    max-width: 500px;
    position: absolute;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    left: 16px;
    top: 94px;

    ${lightDropShadow};
`;
