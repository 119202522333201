import { RenderComponentProps } from 'masonic';
import { ComponentType } from 'react';
import styled from 'styled-components';
import ListingHelper from '../../lib/listing-helper';
import { SelectableListing } from './search-results-masonary';
import TemporalCategoryControl from '../Drawer/Shared/TemporalCategory/temporal-category-control';

const SearchResultsMasonaryCard: ComponentType<RenderComponentProps<SelectableListing>> = (props) => {
    return (
        <ExploreImageContainer>
            <ImageContainer href={`/maps/${props.data.id}`} onClick={(e) => e.preventDefault()}>
                <ImgContainer onClick={() => props.data.handleSelectListing(props.data.id, props.data)}>
                    <ExploreCardImage src={ListingHelper.getPreviewUrlForListing(props.data.id)} loading="lazy" />
                </ImgContainer>

                <OverlaidDiv onClick={() => props.data.handleSelectListing(props.data.id, props.data)}>
                    <OverlayContent>
                        <OverlayDetails>
                            <OverlayTitle>{props.data.title}</OverlayTitle>
                            <OverlayCategoryPill>
                                <TemporalCategoryControl listing={props.data} />
                            </OverlayCategoryPill>
                        </OverlayDetails>
                    </OverlayContent>
                </OverlaidDiv>
            </ImageContainer>
        </ExploreImageContainer>
    );
};

export default SearchResultsMasonaryCard;

const ExploreCardImage = styled.img`
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    border-radius: 6px;
`;

const ExploreImageContainer = styled.div`
    min-height: 85px;
`;

const ImageContainer = styled.a`
    display: block;
    position: relative;
    cursor: pointer;
    margin-right: 12px;
`;

const ImgContainer = styled.div`
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    position: relative;
    vertical-align: middle;
    padding: 0px;
    border-radius: 6px;
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    ${ImageContainer}:hover & {
        opacity: 1;
        background: rgba(0, 0, 0, 0.7);
        border: 1px solid #eed923;
    }
`;

const OverlayContent = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
`;

const OverlayDetails = styled.div`
    width: 100%;
`;

const OverlayTitle = styled.p`
    margin: 0;
    color: #eed926;
    font-size: 1.3rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-align: center;
    overflow-wrap: break-word;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;

const OverlayCategoryPill = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;
