import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiDraw, {
    CreateProjectMetaRequest,
    CreateProjectRequest,
    UpdateProjectRequest,
} from '../../../../../api/api-draw';
import { selectLoggedIn } from '../../../../../store/Account/selectors';
import { LoginModalMode } from '../../../../Registration/login-enum';
import LoginRegisterDialog from '../../../../Registration/login-register-dialog';
import { StyledButton } from '../../../../Shared/styled-button';
import GeoJSONProject, { Project } from '../../Project/geojson-project';
import ProjectMetadataDialog from '../../Project/project-metadata-dialog';
import { DrawPage } from '../draw-story';
import StoryBuilderShareLinkModal from './story-builder-share-link-modal';
import UriHelper from '../../../../../lib/uri-helper';
import { selectMapBounds } from '../../../../../store/App/selectors';
import { setSelectedAnnotationAction, deleteAllAnnotationsAction } from '../../../../../store/Annotations/actions';
import { actionSetDrawStoryBuilderActive, actionClearDrawStory } from '../../../../../store/Map/DrawStory/actions';
import { actionResetDrawProject } from '../../../../../store/Map/DrawProject/actions';
import { SideDrawerMode } from '../../../../../store/SideDrawer/model';
import { PulseLoader } from '../../../../Shared/pulse-loader';
import InfoModal from '../../../../Shared/Modal/info';

interface StoryBuilderSaveButtonProps {
    drawPages: DrawPage[];
    currentDrawProjectId: string | undefined;
}

const MIN_ANNOTATION_COUNT = 1;
const RECOMMENDED_ANNOTATION_COUNT = 4;

const StoryBuilderSaveButton = (props: StoryBuilderSaveButtonProps) => {
    const [showLogin, setShowLogin] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showProjectDialog, setShowProjectDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showShareLinkModal, setShowShareLinkModal] = useState<number | undefined>(undefined);

    const isLoggedIn = useSelector(selectLoggedIn);
    const mapBounds = useSelector(selectMapBounds);
    const dispatch = useDispatch();

    const handleClickSave = () => {
        dispatch(setSelectedAnnotationAction(undefined));
        if (!isLoggedIn) {
            setShowLogin(true);
        } else {
            handleProjectSize();
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleProjectSize = () => {
        const projectSize = getProjectSize();

        if (projectSize < MIN_ANNOTATION_COUNT) {
            setShowWarning(true);
        } else if (projectSize >= MIN_ANNOTATION_COUNT && projectSize < RECOMMENDED_ANNOTATION_COUNT) {
            setShowConfirm(true);
        } else {
            handleConfirm();
        }
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        if (props.currentDrawProjectId) {
            handleUpdateProject();
        } else {
            setShowProjectDialog(true);
        }
    };

    const handleExit = () => {
        setShowWarning(false);

        dispatch(setSelectedAnnotationAction(undefined));
        dispatch(actionSetDrawStoryBuilderActive(false));
        dispatch(actionClearDrawStory());

        dispatch(deleteAllAnnotationsAction());
        dispatch(actionResetDrawProject());
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    const getProjectSize = () => {
        const annotationCount = props.drawPages
            .map((page) => {
                let pageAnnotationCount = 0;
                if (page.mapLayers) {
                    pageAnnotationCount += page.mapLayers.length;
                }
                if (page.project) {
                    pageAnnotationCount += page.project.arrows.length;
                    pageAnnotationCount += page.project.circles.length;
                    pageAnnotationCount += page.project.coordinates.length;
                    pageAnnotationCount += page.project.freehandDraws.length;
                    pageAnnotationCount += page.project.images.length;
                    pageAnnotationCount += page.project.markers.length;
                    pageAnnotationCount += page.project.militaryMarkers.length;
                    pageAnnotationCount += page.project.polygons.length;
                    pageAnnotationCount += page.project.polylines.length;
                    pageAnnotationCount += page.project.rectangles.length;
                    pageAnnotationCount += page.project.textBoxes.length;
                }
                return pageAnnotationCount;
            })
            .reduce((a, b) => a + b, 0);

        return annotationCount;
    };

    const handleProjectGeoJSONData = (drawPages: DrawPage[]) => {
        const pagesGeoJSONRaw = drawPages
            .map((page) => setPagesViewport(page))
            .map((page) => {
                if (page.activeMap && page.project) {
                    page.project = {
                        ...page.project,
                        activeMap: page.activeMap,
                    };
                }
                return {
                    ...page,
                    ...JSON.parse(GeoJSONProject.export(page.project as Project)),
                };
            });

        return { pages: pagesGeoJSONRaw };
    };

    const handleUpdateProject = () => {
        const pagesGeoJSON = handleProjectGeoJSONData(props.drawPages);

        const drawProject: UpdateProjectRequest = {
            data: pagesGeoJSON,
        };

        if (props.currentDrawProjectId) {
            setLoading(true);
            ApiDraw.updateDrawProject(Number(props.currentDrawProjectId), drawProject)
                .then((res) => {
                    setShowShareLinkModal(res.id);
                    UriHelper.navigateToEditDrawProject(res.id.toString());
                })
                .catch(() => {
                    setShowShareLinkModal(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    setShowProjectDialog(false);
                });
        }
    };

    const setPagesViewport = (page: DrawPage): DrawPage => {
        if (!page.project) throw new Error('Project is missing');
        return {
            ...page,
            project: {
                ...page.project,
                viewportBounds:
                    page.viewportLocked || page.allViewportsLocked ? page.project.viewportBounds : mapBounds,
            },
        };
    };

    const handleSaveProject = (projectMetadata: CreateProjectMetaRequest) => {
        if (!isLoggedIn) {
            setShowLogin(true);
            return;
        }

        const pagesGeoJSON = handleProjectGeoJSONData(props.drawPages);

        const drawProject: CreateProjectRequest = {
            ...projectMetadata,
            data: pagesGeoJSON,
        };

        if (props.currentDrawProjectId) {
            setLoading(true);
            ApiDraw.updateDrawProject(Number(props.currentDrawProjectId), drawProject)
                .then((res) => {
                    setShowShareLinkModal(res.id);
                    UriHelper.navigateToEditDrawProject(res.id.toString());
                })
                .catch(() => {
                    setShowShareLinkModal(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    setShowProjectDialog(false);
                });
        } else {
            setLoading(true);
            ApiDraw.createDrawProject(drawProject)
                .then((res) => {
                    setShowShareLinkModal(res.id);
                    UriHelper.navigateToEditDrawProject(res.id.toString());
                })
                .catch(() => {
                    setShowShareLinkModal(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    setShowProjectDialog(false);
                });
        }
    };

    // Rather than passing the setShowProjectDialog all the way down for the social auth
    // we can achieve the same result here
    useEffect(() => {
        if (isLoggedIn && showLogin) {
            setShowLogin(false);
            handleProjectSize();
        }
    }, [isLoggedIn, showLogin, handleProjectSize]);

    return (
        <React.Fragment>
            <SaveButton
                disabled={loading}
                onClick={() => {
                    handleClickSave();
                }}
            >
                {loading ? (
                    <PulseLoader />
                ) : (
                    <React.Fragment>
                        <SaveIcon className="fa fa-save" />
                        Save
                    </React.Fragment>
                )}
            </SaveButton>

            <LoginRegisterDialog
                isOpen={showLogin}
                onClose={() => setShowLogin(false)}
                initialMode={LoginModalMode.LOGIN}
                onLoginSuccess={() => handleClickSave()}
                onRegisterSuccess={() => handleClickSave()}
            />

            <ProjectMetadataDialog
                isOpen={!showLogin && showProjectDialog}
                toggle={() => setShowProjectDialog(false)}
                onSubmit={(projectMetadata: CreateProjectMetaRequest) => {
                    handleSaveProject(projectMetadata);
                }}
                isCreatingProject={loading}
            />

            <InfoModal
                title="Your Stoary map is empty? 😳"
                isOpen={showWarning}
                continueText={'RETURN TO MAP'}
                onToggle={() => setShowWarning(!showWarning)}
                onContinue={() => handleExit()}
            >
                <InfoText>Need help? Watch this video on how to create a Stoary map:</InfoText>
                <InfoHR />
                <br />
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/G97wi-zaTfM?si=SXQLi9onhH3wMyJ5"
                    title="Stoaries Tutorial for Soar.Earth on YouTube"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    frameBorder={1}
                    style={{ borderColor: 'yellow' }}
                />
            </InfoModal>

            <InfoModal
                title={
                    <React.Fragment>
                        Your Stoary map is looking a little short.... <ConfirmEmoji />
                    </React.Fragment>
                }
                isOpen={showConfirm}
                continueText={'RETURN TO MAP'}
                onToggle={() => setShowConfirm(!showConfirm)}
                onContinue={() => setShowConfirm(!showConfirm)}
            >
                <InfoText>Need help? Watch this video on how to create a Stoary map:</InfoText>
                <InfoHR />
                <br />
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/G97wi-zaTfM?si=SXQLi9onhH3wMyJ5"
                    title="Stoaries Tutorial for Soar.Earth on YouTube"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    frameBorder={1}
                    style={{ borderColor: 'yellow' }}
                />
            </InfoModal>

            {showShareLinkModal !== undefined && (
                <StoryBuilderShareLinkModal
                    isOpen={showShareLinkModal !== undefined}
                    toggle={() => setShowShareLinkModal(undefined)}
                    savedProjectId={showShareLinkModal}
                />
            )}
        </React.Fragment>
    );
};

export default StoryBuilderSaveButton;

const ConfirmEmoji = styled.i`
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url(/assets/emojis/emoji-smirk-glasses.png) no-repeat center;
    background-size: cover;
    margin-bottom: -4px; // aligns the emoji with the text;
`;

const SaveButton = styled(StyledButton)`
    display: block;
    margin: 8px auto;
    min-width: 152px;
`;

const SaveIcon = styled.i`
    margin-right: 4px;
`;

const InfoText = styled.p`
    color: white;
    font-size: 18px;
`;

const InfoHR = styled.hr`
    border-top: 1px solid rgba(255, 255, 255, 0.5);
`;
