import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../api/api-user';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { actionLoginSuccess } from '../../store/Account/actions';
import { StyledButton } from '../Shared/styled-button';
import { SubmittingSpinner } from '../Shared/submitting-spinner';
import CognitoUtil, { CognitoAuthResultType } from '../../lib/cognito-util';
import OAuthSocialLogins from './OAuth2/oauth-social-logins';

interface RegisterProps {
    email: string;
    onEmailChange: (email: string) => void;
    onClose: () => void;
    handleClickLogin: () => void;
    onRegisterSuccess: () => void;
    hideFooter?: boolean;
}

const Register = (props: RegisterProps) => {
    const dispatch = useDispatch();
    const loginSuccess = (authResponse: CognitoAuthResultType) => dispatch(actionLoginSuccess(authResponse));

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [newsletterSignUp, setNewsletterSignUp] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const submitRegister = async () => {
        setIsSubmitting(true);

        try {
            //@ts-ignore
            window.gtag('config', 'AW-752086192');

            //@ts-ignore
            window.gtag('event', 'conversion', { send_to: 'AW-752086192/e-4PCLXvj_kBELDZz-YC' });
        } catch {
            //do nothing for now, implemented this try catch so the integration testing will work
        }

        try {
            // TODO: Adds newsletter sign-up field to endpoint.
            await ApiUser.signup(
                props.email,
                password,
                firstName + ' ' + lastName,
                firstName,
                lastName,
                newsletterSignUp
            );

            CognitoUtil.login(props.email, password).then((authResponse) => {
                loginSuccess(authResponse);
                Analytics.Event('Registration', 'Registered', `${firstName} ${lastName}`);
                props.onRegisterSuccess();
            });
        } catch (err) {
            const message: string = err.message;
            if (message) {
                setError(message.substring(4));
            } else {
                setError('Something went wrong');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmitRegister = (e) => {
        e.preventDefault();
        setError(undefined);
        if (!firstName || !lastName) {
            setError('Please check your name');
            return;
        }

        if (!UserHelper.isEmailValid(props.email)) {
            setError('Please check your email');
            return;
        }

        if (password.length < 6) {
            setError('Please enter a password that is at least 6 characters long');
            return;
        }

        if (!acceptedTerms) {
            setError('You must accept the terms and conditions to register');
            return;
        }

        if (firstName && lastName && props.email && password && acceptedTerms) {
            submitRegister();
        }
    };

    return (
        <React.Fragment>
            <RegisterModalBody>
                <OAuthSocialLogins />
                <Form onSubmit={(e) => handleSubmitRegister(e)}>
                    <FormGroup>
                        <Label for="firstName">Enter your name</Label>
                        <RegisterFormName>
                            <Input
                                data-testid="first-name"
                                style={{ marginRight: '5px' }}
                                placeholder="First Name..."
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setError(undefined);
                                }}
                            />
                            <Input
                                data-testid="last-name"
                                placeholder="Last Name..."
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                    setError(undefined);
                                }}
                            />
                        </RegisterFormName>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            data-testid="email"
                            type="email"
                            placeholder="Email Address"
                            value={props.email}
                            onChange={(e) => {
                                props.onEmailChange(e.target.value);
                                setError(undefined);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Create Password</Label>
                        <Input
                            data-sentry-block
                            data-testid="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                        />
                        <RegisterFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                            />
                            I have read, understood and agree to the
                            <RegisterLink target="_blank" href="https://soar.earth/terms">
                                Terms of Use
                            </RegisterLink>{' '}
                            and
                            <RegisterLink target="_blank" href="https://soar.earth/privacy">
                                Privacy Policy
                            </RegisterLink>
                        </RegisterAcceptTerms>

                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={newsletterSignUp}
                                onChange={() => setNewsletterSignUp(!newsletterSignUp)}
                            />
                            I would like to sign up to the newsletter to receive regular updates.
                        </RegisterAcceptTerms>
                    </FormGroup>
                    {error && (
                        <RegisterModalError>
                            <RegisterModalErrorIcon />
                            {error}
                        </RegisterModalError>
                    )}
                    {!isSubmitting ? (
                        <RegisterSubmitButton onClick={(e) => handleSubmitRegister(e)} error={error ? true : false}>
                            CREATE ACCOUNT
                        </RegisterSubmitButton>
                    ) : (
                        <SubmittingSpinner />
                    )}
                </Form>
            </RegisterModalBody>
            {!props.hideFooter ? (
                <RegisterModalFooter>
                    Already have an account?
                    <RegisterLink onClick={props.handleClickLogin}>Sign in here</RegisterLink>
                </RegisterModalFooter>
            ) : null}
        </React.Fragment>
    );
};

export default Register;

const RegisterModalError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const RegisterModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const RegisterModalBody = styled(ModalBody)`
    -webkit-text-fill-color: white;

    label {
        color: rgba(255, 255, 255, 0.87);
        width: 100%;
    }

    input {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgb(255 255 255 / 0.3) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;

        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        }

        :focus {
            background-color: transparent;
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        }
    }
`;

const RegisterFormName = styled.div`
    display: inline-flex;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const RegisterFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url('${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)}');
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -26px;
    margin-right: 5px;
`;

const passwordActiveUrl =
    "data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M0.999971 10.0023L0.554051 9.77608C0.481944 9.91824 0.481944 10.0863 0.554051 10.2284L0.999971 10.0023ZM23 10.0023L23.4459 10.2284C23.518 10.0863 23.518 9.91824 23.4459 9.77608L23 10.0023ZM1.44589 10.2284C3.85237 5.4839 7.72171 2.5 12 2.5V1.5C7.21666 1.5 3.06385 4.82785 0.554051 9.77608L1.44589 10.2284ZM12 2.5C16.2782 2.5 20.1476 5.4839 22.5541 10.2284L23.4459 9.77608C20.9361 4.82785 16.7833 1.5 12 1.5V2.5ZM22.5541 9.77608C20.1476 14.5206 16.2782 17.5045 12 17.5045V18.5045C16.7833 18.5045 20.9361 15.1767 23.4459 10.2284L22.5541 9.77608ZM12 17.5045C7.72171 17.5045 3.85237 14.5206 1.44589 9.77608L0.554051 10.2284C3.06385 15.1767 7.21666 18.5045 12 18.5045V17.5045Z' fill='white'/%3E%3Ccircle cx='12' cy='10' r='4' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A";
const passwordInActiveUrl =
    "data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M21 1L3 19' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M1 10.0023L0.55408 9.77608C0.481973 9.91824 0.481973 10.0863 0.55408 10.2284L1 10.0023ZM23 10.0023L23.4459 10.2284C23.518 10.0863 23.518 9.91824 23.4459 9.77608L23 10.0023ZM1.44592 10.2284C3.8524 5.4839 7.72174 2.5 12 2.5V1.5C7.21669 1.5 3.06388 4.82785 0.55408 9.77608L1.44592 10.2284ZM12 2.5C16.2783 2.5 20.1476 5.4839 22.5541 10.2284L23.4459 9.77608C20.9361 4.82785 16.7833 1.5 12 1.5V2.5ZM22.5541 9.77608C20.1476 14.5206 16.2783 17.5045 12 17.5045V18.5045C16.7833 18.5045 20.9361 15.1767 23.4459 10.2284L22.5541 9.77608ZM12 17.5045C7.72174 17.5045 3.8524 14.5206 1.44592 9.77608L0.55408 10.2284C3.06388 15.1767 7.21669 18.5045 12 18.5045V17.5045Z' fill='white'/%3E%3Ccircle cx='12' cy='10' r='4' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E";

const RegisterAcceptTerms = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 12px;
    text-align: left;
    margin: 0 0 12px 12px;
`;

interface ErrorProps {
    error: boolean;
}

const RegisterSubmitButton = styled(StyledButton)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    margin-bottom: 10px;
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
    }
`;

const RegisterModalFooter = styled(ModalFooter)`
    color: rgba(255, 255, 255, 0.87);
    border-top: 1px solid rgb(255 255 255 / 0.3);
    display: block;
    text-align: center;
`;

const RegisterLink = styled.a`
    color: #eed926 !important;
    -webkit-text-fill-color: #eed926 !important;
    margin-left: 3px;
    text-decoration: underline !important;
    cursor: pointer;
`;
