import styled from 'styled-components';
import DiscoverSearchResults from './discover-search-results';
import DiscoverRecentlyViewedMaps from './discover-recently-viewed-maps';

const SideDrawerDiscover = () => {
    return (
        <DiscoverMasonryContainer>
            <DiscoverSearchResults selectedSearchTerm={''} selectedOrder={''}>
                <DiscoverRecentlyViewedMaps />
            </DiscoverSearchResults>
        </DiscoverMasonryContainer>
    );
};

export default SideDrawerDiscover;

const DiscoverMasonryContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100vw - 80px);
    height: calc(100vh - 75px);
`;
