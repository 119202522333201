import styled from 'styled-components';
import { UserDTO } from '../../../api/model';
import PointsUtil from '../../NavigationBar/Points/points-util';
import ProfileName from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import UserAvatar from '../../Shared/Avatar/user-avatar';

interface DashboardHomeProfileProps {
    profile: UserDTO;
}

const DashboardHomeProfile = (props: DashboardHomeProfileProps) => {
    return (
        <ProfileContainer>
            <UserAvatar user={props.profile} margin="0" diameter={80} />
            <ProfileName isCommunityModerator={props.profile.communityModerator} isReputable={props.profile.reputable}>
                {props.profile.name ?? 'Soar User'}
            </ProfileName>
            <ProfilePoints>{PointsUtil.pointsFormatter(props.profile.points)} points</ProfilePoints>
        </ProfileContainer>
    );
};

export default DashboardHomeProfile;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
`;

const ProfilePoints = styled.span`
    color: #eed926;
    font-weight: bold;
`;
