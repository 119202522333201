import React from 'react';
import { useSelector } from 'react-redux';
import { selectMyProfile } from '../../../../../store/Account/selectors';
import styled from 'styled-components';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';

interface StoryMapControlEditProps {
    creatorId: string;
}

const StoryMapControlEdit = ({ creatorId }: StoryMapControlEditProps) => {
    const myProfile = useSelector(selectMyProfile);

    const handleEdit = () => {
        const urlOrigin = new URL(window.location.href);
        const storyId = urlOrigin.pathname.split('/')[2];
        if (storyId) {
            Analytics.Event('Draw Tools - Project', `Creator clicked to edit own project while viewing`, storyId);
            UriHelper.navigateToEditDrawProject(storyId);
        }
    };

    if (myProfile?.userId !== creatorId) {
        return <React.Fragment />;
    }

    return <EditIcon onClick={handleEdit} className={'fa fa-pen'} title="Edit Stoary" />;
};

export default StoryMapControlEdit;

const EditIcon = styled.i`
    display: flex;
    justify-content: flex-end;
    margin: 5px 5px 0px 0px;
    padding: 5px;
    color: white;
    font-size: 18px;
    pointer-events: all;
    cursor: pointer;

    :hover {
        color: #eed926;
    }
`;
