import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { Tooltip } from 'react-tooltip';
import Api from '../../../api/api';
import ApiAnalytics from '../../../api/api-analytics';
import ApiListings from '../../../api/api-listings';
import ApiSubdomain from '../../../api/api-subdomain';
import { AnalyticsAction, ListingDTO, AnalyticsNote, ListingType, Review } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import SoarHelper, { isMobileVersion } from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import profileIconUrl from '../profile-icon-url';
import ImageryEditDropdown from './imagery-edit-dropdown';
import ImageryShare from './imagery-share';
import ImagePreviewFailed from '../../Shared/image-preview-failed';

interface ProfileMasonaryCardProps {
    listing: ListingDTO;
    onRefresh?: () => void;
    onEdit?: (id: number) => void;
    onDelete?: (id: number) => void;
    onReport?: (id: number) => void;
    handleAction?: (action: string) => void;
    dataTestId?: string;
}

const ImageryMasonaryCard = (props: ProfileMasonaryCardProps) => {
    const { listing, onEdit, onDelete, onReport, handleAction } = props;
    const isSoar = SoarHelper.isSoar();
    const [editImage, setEditImage] = useState<boolean>(false);
    const [imageFailed, setImageFailed] = useState(false);

    const listingTitle = ListingHelper.getTitleFromListing(listing);

    const download = () => {
        if (listing.listingType === ListingType.IMAGE) {
            downloadImage();
        } else {
            downloadMap();
        }
    };

    const downloadImage = useCallback(() => {
        ApiListings.getDownloadUrl(listing.id)
            .then((downloadUrl) => {
                Analytics.Event('My Dashboard', 'Downloading Image', props.listing.id);
                ApiAnalytics.postAnalyticsListing(AnalyticsAction.DOWNLOAD, AnalyticsNote.MY_IMAGERY, props.listing.id);
                if (downloadUrl) {
                    toast.dark(`Downloading ${listingTitle}`);
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', downloadUrl.presignedUrl, true);
                    xhr.responseType = 'blob';
                    xhr.onload = function () {
                        const urlCreator = window.URL || window.webkitURL;
                        const imageUrl = urlCreator.createObjectURL(this.response);
                        const link = document.createElement('a');
                        link.href = imageUrl;
                        link.download = downloadUrl.filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
                    xhr.send();
                }
            })
            .catch((error) => {
                toast.error('Unable to download, Please try again ', error);
            });
    }, [props.listing.id, listing.id, listingTitle]);

    const downloadMap = () => {
        Analytics.Event('My Profile', 'Downloading Map', props.listing.id);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.DOWNLOAD, AnalyticsNote.MY_IMAGERY, props.listing.id);
        ApiSubdomain.getListingDownloadLink(listing)
            .then((response) => {
                // Construct the filename to download as from the original listing filename
                let filename = listing.filename;
                const fileparts = filename.split('/');
                filename = fileparts[fileparts.length - 1];

                // Create an <a> element with the href given to us as the response and click that element
                const element = document.createElement('a');
                element.setAttribute('href', response);
                element.setAttribute('download', filename);
                element.setAttribute('target', '_blank');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                toast.dark(`Downloading ${listingTitle}`);
            })
            .catch(() => {
                toast.dark(`Error downloading ${listingTitle}`);
            });
    };

    const status = () => {
        if (listing.listingType === ListingType.NOT_SUPPORTED) {
            return <StatusLabel className="review">NOT SUPPORTED</StatusLabel>;
        }
        switch (Api.apiReviewToEnum(listing.review)) {
            case Review.APPROVED:
                return <StatusLabel className="approved">APPROVED</StatusLabel>;
            case Review.REJECTED:
                return <StatusLabel className="rejected">REJECTED</StatusLabel>;
            case Review.PENDING:
                return <StatusLabel className="rejected">PENDING</StatusLabel>;
            default:
                return <StatusLabel className="review">NOT SUPPORTED</StatusLabel>;
        }
    };

    const flyToSupermap = () => {
        if (listing.review && listing.review === Review.APPROVED && !listing.error) {
            UriHelper.navigateToMap(listing.id);
            ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.MY_IMAGERY, props.listing.id);
            Analytics.Event('My Dashboard', 'Clicked to view on map', listing.id);
        }
    };

    // NOTE: some of the dropdown behavior will work with IMAGE but to replicate current behavior
    // it has been disabled for this imagery type.
    return (
        <Container data-testid={props.dataTestId}>
            <ProfileImageryEditIcon
                src={!editImage ? profileIconUrl.editDropdownInActive : profileIconUrl.editDropdownActive}
                onClick={() => setEditImage(!editImage)}
            />
            {editImage && (
                <ImageryEditDropdown
                    setEditDetailsOpen={() => onEdit?.(listing.id)}
                    setEditImage={setEditImage}
                    handleShowDelete={() => onDelete?.(listing.id)}
                    download={() => {
                        download();
                    }}
                    setIsContactFormActive={() => onReport?.(listing.id)}
                    listing={listing}
                    handleAction={handleAction}
                />
            )}
            {listing.review === 'APPROVED' && listing.listingType !== ListingType.ORDER && (
                <ImageryShare dataTestId={props.dataTestId && `${props.dataTestId}-share`} listing={listing} />
            )}
            <ImageContainer
                approved={listing?.review === Review.APPROVED}
                onClick={(e) => {
                    e.preventDefault();
                    if (!isMobileVersion) flyToSupermap();
                }}
                as={!isMobileVersion && listing?.review === Review.APPROVED ? 'a' : undefined}
                href={`/maps/${listing.id}`}
            >
                <StatusContainer>{status()}</StatusContainer>
                <ImgContainer onClick={() => setEditImage(false)}>
                    {listing.id ? (
                        imageFailed ? (
                            <ProfileImageryCardImageFailed />
                        ) : (
                            <ProfileImageryCardImage
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (isMobileVersion) flyToSupermap();
                                }}
                                src={ListingHelper.getPreviewUrlForListing(listing.id)}
                                onError={() => setImageFailed(true)}
                            />
                        )
                    ) : (
                        <ProfileImageryCardRejected />
                    )}
                    {listing.review === Review.APPROVED && listing.processing && (
                        <WarningDescription>
                            Your map is being processed at the moment. Please allow up to 24 hours for it to become
                            available.
                        </WarningDescription>
                    )}
                </ImgContainer>
                {(!isMobileVersion || listing.rejectionReasons || listing.error) && (
                    <OverlaidDiv>
                        {listing.rejectionReasons && listing.review === Review.REJECTED && (
                            <RejectionReasons>
                                {listing.rejectionReasons.map((rejection) => (
                                    <RejectionReasonItem>
                                        <span
                                            data-tooltip-place="bottom-end"
                                            data-tooltip-id={`reject-reason`}
                                            data-tooltip-content={rejection.feedback}
                                        >
                                            {rejection.reason}
                                        </span>
                                    </RejectionReasonItem>
                                ))}
                            </RejectionReasons>
                        )}
                        {listing.error && <RejectionDescription>{listing.error}</RejectionDescription>}
                    </OverlaidDiv>
                )}
                {listing.rejectionReasons && listing.rejectionReasons.length > 0 && (
                    <Tooltip id="rejection-reasons-tooltip" />
                )}
                <ProfileImageryCardDetails
                    onClick={(e) => {
                        e.preventDefault();
                        if (isMobileVersion) flyToSupermap();
                    }}
                >
                    <ProfileImageryCardHeading>
                        <TitleContainer>
                            <ProfileImageryCardTitle>{listingTitle}</ProfileImageryCardTitle>
                        </TitleContainer>
                        {isSoar && listing.listingType !== ListingType.ORDER && listing.review !== Review.REJECTED && (
                            <ShareIconContainer>
                                <ShareIcon src={profileIconUrl.heartUrl} />
                                <p>{listing.totalLikes}</p>
                            </ShareIconContainer>
                        )}
                    </ProfileImageryCardHeading>
                    <ProfileImageryCardInfo>
                        {listing.description}
                        <DateTaken>{moment.unix(listing.createdAt).format('DD.MM.YYYY')}</DateTaken>
                        {listing.userName && <UserName>{`by ${listing.userName}`}</UserName>}
                    </ProfileImageryCardInfo>
                </ProfileImageryCardDetails>
            </ImageContainer>
        </Container>
    );
};

export default ImageryMasonaryCard;

interface Approved {
    approved: boolean;
}

const ProfileImageryCardImageFailed = styled(ImagePreviewFailed)`
    height: 230px;
`;

const ProfileImageryCardImage = styled.img`
    border: 1px solid transparent;
    width: 100%;
    height: 250px;
    background-color: rgb(255 255 255 / 2%);
    border-radius: 6px;
    display: flex;
    transition: height 5s ease-in-out;

    ${isMobileVersion &&
    css`
        border: none;
        width: auto;
        margin: auto;
        object-fit: contain;
        border-radius: 0px;
        background-color: hsla(0, 100%, 100%, 0.02);
        box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
            0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    `};
`;

const ProfileImageryCardRejected = styled.div`
    height: 250px;
    background-color: rgb(255 255 255 / 2%);
    border: 1px solid transparent;
`;

const TitleContainer = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 210px;
    word-break: break-word;
`;

const ProfileImageryCardTitle = styled.p`
    color: #fff;
    padding-top: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.03em;
    margin-bottom: 5px;
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    overflow: hidden;
`;

const ProfileImageryCardInfo = styled.div`
    height: 120px;
    color: white;
    font-size: 18px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ProfileImageryCardDetails = styled.div`
    padding: 5px;
    height: 110px;
`;

const ProfileImageryEditIcon = styled.img`
    position: absolute;
    padding: 10px;
    padding-right: 16px; // icon padding plus container padding for position
    z-index: 100;
    right: 0px;
    cursor: pointer;
`;

const Container = styled.div`
    padding: 6px;
    position: relative;

    div.share {
        opacity: 0;
    }

    &:hover div.share {
        opacity: 1;
    }

    ${isMobileVersion &&
    css`
        padding: 10px 5px 5px 5px;
        margin: 10px 0px;
        border-radius: 6px;
        border: 1px solid rgb(255 255 255 / 10%);
        background-color: hsla(0, 100%, 100%, 0.01);
        box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
            0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    `};
`;

const ImageContainer = styled.div<Approved>`
    display: block;
    position: relative;
    ${(props) => props.approved && `cursor: pointer;`}
    text-decoration: none !important;
    overflow-y: hidden;
`;

const ImgContainer = styled.div`
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    position: relative;
    vertical-align: middle;
    padding: 0px;
    border-radius: 6px;
    overflow: hidden;

    ${isMobileVersion &&
    css`
        border-radius: 0px;
        background-color: hsla(0, 100%, 100%, 0.02);
    `};
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;
    border: 1px solid rgb(255 255 255 / 25%);

    ${ImageContainer}:hover & {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
    }
`;

const DateTaken = styled.p`
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    line-height: 20%;
    margin-top: 5px;
`;

const UserName = styled.div`
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin-top: 5px;
    word-break: break-all;
    max-height: 60px;
`;

const RejectionReasons = styled.ul`
    opacity: 0.9;
    padding-top: 30px;
    margin: 0px 5px;
`;
const RejectionReasonItem = styled.li`
    cursor: info;
    user-select: none;

    &::marker {
        color: red;
    }

    span {
        color: red;
        font-weight: bold;
    }
`;

const RejectionDescription = styled.p`
    opacity: 0.8;
    display: flex;
    flex-wrap: wrap;
    color: red;
    padding-top: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
    margin: 0px 5px;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;

const WarningDescription = styled.div`
    opacity: 0.8;
    display: flex;
    flex-wrap: wrap;
    color: #eed926;
    position: absolute;
    top: 25%;
    padding: 10px;
`;

const StatusContainer = styled.div`
    position: absolute;
    z-index: 102;
    text-align: center;
    margin: 5px;
`;

const StatusLabel = styled.p`
    background-color: rgb(0, 0, 0, 0.8);
    height: 22px;
    width: 70px;
    border-radius: 6px;

    &.approved {
        color: green;
        display: none;
    }

    &.review {
        color: orange;

        font-size: 12px;
        font-weight: bold;
        padding: 2px 5px 2px 5px;
    }

    &.rejected {
        color: red;

        font-size: 12px;
        font-weight: bold;
        padding: 2px 5px 2px 5px;
    }
`;

const ProfileImageryCardHeading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;

    p {
        color: white;
    }
`;

const ShareIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 7px 0px 0px 5px;
    text-align: center;

    p {
        margin-top: -2px;
        margin-bottom: 0px;
    }
`;

const ShareIcon = styled.img`
    width: 18px;
    height: 18px;
    padding-right: 5px;
`;
