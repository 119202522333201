import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';

import store from '../../../store/store';
import { actionToggleLocationSearch } from '../../../store/Search/actions';

const styles = `
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
`;

const inActiveStyle = `
    background-color: rgba(0, 0, 0, 0.8) !important;
`;

interface LocationSearchControlOptions extends L.ControlOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: any;
    onToggle: (active: boolean) => void;
    isActive?: boolean;
}

class LocationSearchControlClass extends L.Control {
    _locationSearchButton: HTMLButtonElement | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _currentStyle: string;
    _isActive: boolean;
    _onToggle: (active: boolean) => void;
    _map: L.Map; // Add _map property

    constructor(options: LocationSearchControlOptions) {
        super(options);
        this.options.position = options.position;
        this._onToggle = (active: boolean) => options.onToggle(active);
        this._isActive = options.isActive ?? false;
    }

    onAdd(map: L.Map) {
        // Add map parameter
        this._map = map; // Assign map to _map property

        const locationSearchButton: HTMLButtonElement = L.DomUtil.create('button');
        locationSearchButton.innerHTML = `<img style="width: 28px; height: 28px; opacity: 0.8;" src="data:image/svg+xml,%3Csvg%20width%3D%2232px%22%20height%3D%2232px%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M%2019%203%20C%2013.489%203%209%207.489%209%2013%20C%209%2015.395%209.839%2017.5875%2011.25%2019.3125%20L%203.28125%2027.28125%20L%204.71875%2028.720703%20L%2012.6875%2020.751953%20C%2014.4115%2022.161953%2016.605%2023%2019%2023%20C%2024.511%2023%2029%2018.511%2029%2013%20C%2029%207.489%2024.511%203%2019%203%20z%20M%2019%205%20C%2023.43%205%2027%208.57%2027%2013%20C%2027%2017.43%2023.43%2021%2019%2021%20C%2014.57%2021%2011%2017.43%2011%2013%20C%2011%208.57%2014.57%205%2019%205%20z%20M%2019%208%20C%2016.791%208%2015%209.791%2015%2012%20C%2015%2015%2019%2019%2019%2019%20C%2019%2019%2023%2015%2023%2012%20C%2023%209.791%2021.209%208%2019%208%20z%20M%2019%2010%20C%2020.105%2010%2021%2010.895%2021%2012%20C%2021%2013.104%2020.105%2014%2019%2014%20C%2017.895%2014%2017%2013.104%2017%2012%20C%2017%2010.895%2017.895%2010%2019%2010%20z%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E" />`;
        locationSearchButton.setAttribute('style', styles);
        locationSearchButton.setAttribute('id', 'location-search-button');

        this._locationSearchButton = locationSearchButton;

        locationSearchButton.onclick = () => {
            if (!this._isActive) {
                this._searchActive();
            } else {
                this._searchInactive();
            }
        };

        return locationSearchButton;
    }

    _searchActive() {
        this._isActive = true;

        if (this._locationSearchButton) {
            this._locationSearchButton.setAttribute('style', `${styles}`);
        }

        L.DomEvent.on(this._map.getContainer(), 'mouseup', this._handleMouseUp, this);

        this._onToggle(this._isActive);
    }

    _searchInactive() {
        this._isActive = false;

        if (this._locationSearchButton) {
            this._locationSearchButton.setAttribute('style', `${styles} ${inActiveStyle}`);
        }

        L.DomEvent.off(this._map.getContainer(), 'mouseup', this._handleMouseUp, this);

        this._onToggle(this._isActive);
    }

    _handleMouseUp() {
        console.log('_handleMouseUp', this);
        this._searchInactive();
    }
}

const locationSearchControl = () => {
    const handleToggle = (active: boolean) => {
        store.dispatch(actionToggleLocationSearch(active));
    };

    return new LocationSearchControlClass({
        position: 'topright',
        style: styles,
        isActive: store.getState().searchDomain.isLocationSearchActive,
        onToggle: handleToggle,
    });
};

const LocationSearchControl = createControlComponent(locationSearchControl);

export default LocationSearchControl;
