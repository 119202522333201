import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../store/SideDrawer/selectors';
import SearchContentBookmarks from './search-content-bookmarks';
import SearchContentHistory from './search-content-history';
import SearchContentMapsInViewport from './search-content-maps-in-viewport';
import SearchContentSimilar from './search-content-similar';
import SearchContentSimilarUser from './search-content-similar-user';
import SearchModalContainer from './search-modal-container';
import SearchContentSentinelInViewport from './search-content-satellite-in-viewport';

import { DrawPageMapLayer } from '../MapView/Annotations/StoryMaps/draw-story';

interface SearchContainerProps {
    isOpen: boolean;
    children?: React.ReactElement;
    toggle: () => void;
    onSelectMapLayer: (mapLayer: DrawPageMapLayer) => void;
}

const OPEN_ANIMATION_TIME = 300; //ms
const OPEN_ANIMATION_DELAY = 100; //ms
const CLOSE_ANIMATION_TIME = 200; //ms

const SearchModal = (props: SearchContainerProps) => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    const [containerClassName, setContainerClassName] = useState<'opening' | 'open' | 'closing' | 'closed'>('closed');

    const closeSearch = (onClose?) => {
        setContainerClassName('closing');
        setTimeout(() => {
            setContainerClassName('closed');
            if (onClose) {
                onClose();
            }
        }, CLOSE_ANIMATION_TIME);
    };

    const openSearch = () => {
        setContainerClassName('opening');
        setTimeout(() => setContainerClassName('open'), OPEN_ANIMATION_TIME);
    };

    useEffect(() => {
        if (sideDrawerMode === SideDrawerMode.SHARE_MAP && containerClassName !== 'closed') {
            closeSearch(() => {
                props.toggle();
            });
        } else {
            if (props.isOpen && containerClassName === 'closed') {
                openSearch();
            } else if (!props.isOpen && containerClassName === 'open') {
                closeSearch();
            }
        }
    }, [containerClassName, props, sideDrawerMode]);

    const isOpen = containerClassName === 'open';

    return (
        <React.Fragment>
            <PageFillContainer
                onClick={() => {
                    props.toggle();
                }}
                className={props.isOpen ? 'open' : 'closed'}
            />

            <Container className={containerClassName}>
                {isOpen ? (
                    <React.Fragment>
                        <ContainerBorderRemove />
                        <SearchModalContainer toggle={() => props.toggle()} onSelectItem={props.onSelectMapLayer}>
                            <SearchContent>
                                <SearchContentMapsInViewport onSelectItem={props.onSelectMapLayer} />
                                <SearchContentSentinelInViewport onSelectItem={props.onSelectMapLayer} />
                                <SearchContentHistory onSelectItem={props.onSelectMapLayer} />
                                <SearchContentSimilar onSelectItem={props.onSelectMapLayer} />
                                <SearchContentSimilarUser onSelectItem={props.onSelectMapLayer} />
                                <SearchContentBookmarks onSelectItem={props.onSelectMapLayer} />
                            </SearchContent>
                        </SearchModalContainer>
                    </React.Fragment>
                ) : null}
            </Container>
        </React.Fragment>
    );
};

export default SearchModal;

const openAnimation = keyframes`
    from {
        min-height: 0;
        width: 40vw;
        left: 35vw;
    }
    to {
        min-height: 75vh;
        width: 74vw;
        left: 17vw;
    }
`;

const closeAnimation = keyframes`
    from {
        min-height: 75vh;
        width: 74vw;
        left: 17vw;

    }
    to {
        min-height: 0vh;
        width: 40vw;
        left: 35vw;
    }
`;

const PageFillContainer = styled.div`
    position: fixed;
    top: -48px;
    left: 0;
    right: 0%;
    bottom: 0;
    opacity: 0;
    z-index: 9999;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 200ms ease-in-out 0ms;
    cursor: default;

    &.open {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        opacity: 1;
    }

    &.closed {
        border-top: none;
        opacity: 0;
        user-select: none;
        pointer-events: none;
    }
`;

const Container = styled.div`
    cursor: default;
    width: 74vw;
    margin: 0 auto;
    padding: 0;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    transition: min-height ${OPEN_ANIMATION_TIME}ms ease-in-out 0ms;
    border-radius: 6px;
    position: fixed;
    top: 136px;
    left: calc((100vw - 74vw) / 2);
    z-index: 10000;
    min-height: 0;
    max-height: 75vh;
    overflow-y: scroll;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #eed926;
        border-radius: 4px;
    }

    &.opening {
        animation: ${openAnimation} ${OPEN_ANIMATION_TIME}ms forwards;
        animation-delay: ${OPEN_ANIMATION_DELAY}ms;
    }

    &.open {
        min-height: 75vh;
        -webkit-box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151;
    }

    &.closing {
        animation: ${closeAnimation} ${CLOSE_ANIMATION_TIME}ms forwards;
        min-height: 0;
    }

    &.closed {
        min-height: 0;
        display: none;
    }
`;

const SearchContent = styled.div`
    margin-bottom: 60px;
`;

const ContainerBorderRemove = styled.div`
    margin: 0 auto;
    padding: 0;
    display: block;
    background: transparent;
    transition: min-height ${OPEN_ANIMATION_TIME}ms ease-in-out 0ms;
    border-radius: 6px;
    position: fixed;
    top: 140px;
    width: calc(40vw - 2px);
    left: calc(35vw + 1px);
    height: 5px;
    z-index: 10001;
`;

export const SegmentContainer = styled.div`
    margin: 60px 3vw 0 3vw;
    padding: 16px;
    background: #181919;
    border-radius: 10px;
`;

export const SegmentTitle = styled.h4`
    color: white;
    text-align: left;
    font-size: 1.6rem;

    span {
        color: #eed923;
    }
`;

export const SegmentTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    margin: -4px 8px 0 8px;
`;

export const SegmentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const SegmentFilters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem; ;
`;

export const SegmentContent = styled.div`
    height: 180px;
`;

export const Select = styled.select`
    height: 28px;
    border: none;
    outline: none;
    background-color: transparent;
    min-width: 85px;

    color: ${(props) => props.theme.color.white};

    &:focus {
        outline: none;
    }

    &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 20px;
        border: 1px solid ${(props) => props.theme.color.yellow};
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
`;

export const SelectOption = styled.option`
    color: ${(props) => props.theme.color.white};
    padding-right: 5px;
    background-color: ${(props) => props.theme.color.transparentDark};
`;

export const SelectLabel = styled.label`
    margin: 0 5px;
    border: 1px solid ${(props) => props.theme.color.opaqueWhite};
    border-radius: ${(props) => props.theme.borderRadius};
    overflow: hidden;

    ${Select} {
        margin-left: 28px;
    }
`;

export const SatelliteSelect = styled(SelectLabel)`
    background-image: url('/assets/map-categories/button_satellite.png');
    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: auto 75%;
`;

export const LayerSelect = styled(SelectLabel)`
    background-image: url('/assets/sentinel-filter/layers.svg');
    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: auto 75%;
`;

export const DateSelect = styled(SelectLabel)`
    background-image: url('/assets/sentinel-filter/calendar.svg');
    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: auto 70%;
`;
