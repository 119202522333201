import styled from 'styled-components';
import SoarHelper from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { useDispatch } from 'react-redux';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { deleteAllAnnotationsAction } from '../../../store/Annotations/actions';
import { actionResetDrawProject } from '../../../store/Map/DrawProject/actions';

interface MobileSoarLogoProps {
    handleClicked?: () => void;
}

const MobileSoarLogo = (props: MobileSoarLogoProps) => {
    const dispatch = useDispatch();

    const handleClickedLogo = () => {
        Analytics.Event('Navbar', 'Mobile Navbar - Clicked Logo');
        dispatch(actionActiveMapCleared());
        dispatch(deleteAllAnnotationsAction());
        dispatch(actionResetDrawProject());
        UriHelper.navigateToPath('/maps');
        props.handleClicked && props.handleClicked();
    };

    return SoarHelper.isSoar() ? (
        <SoarLogo
            onClick={handleClickedLogo}
            src={'/assets/logos/soar-icon-search.png'}
            data-testid="mobile-soar-logo"
        />
    ) : (
        <SoarSubdomainLogo
            onClick={handleClickedLogo}
            src={'/assets/subdomain/subdomain-soar-icon-search.png'}
            data-testid="mobile-soar-plus-logo"
        />
    );
};

export default MobileSoarLogo;

const SoarLogo = styled.img`
    position: absolute;
    z-index: 100;
    cursor: pointer;
    margin: 3px 1px;
    height: 52px;
`;

const SoarSubdomainLogo = styled.img`
    position: absolute;
    z-index: 100;
    cursor: pointer;
    margin: 5px 1px;
    height: 45px;
    width: 49px;
`;
