import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
    Navbar as BootstrapNavbar,
    NavbarBrand as BootstrapNavbarBrand,
} from 'reactstrap';
import styled from 'styled-components';
import SoarHelper from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { selectLoggedIn, selectMyProfile } from '../../store/Account/selectors';
import { actionShowLoginDialog } from '../../store/App/actions';
import { selectConfig, selectShowNavbar } from '../../store/App/selectors';
import { actionGetNotifications } from '../../store/Notifications/actions';
import { Notifications } from '../SocialMapping/Notifications/notifications';
import NavbarProfile from './navbar-profile';
import { NavbarSubdomainList } from './navbar-subdomain-list';

const NavigationBar = () => {
    const config = useSelector(selectConfig);
    const userLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);
    const showNavbar = useSelector(selectShowNavbar);

    const dispatch = useDispatch();

    const checkNotifications = () => {
        if (userLoggedIn && myProfile) {
            dispatch(actionGetNotifications());
        }
    };

    const onClickLogo = () => {
        Analytics.Event('Navigation', 'Clicked Logo');
        checkNotifications();
        UriHelper.navigateToPath('/');
    };

    const onClickLogin = () => {
        Analytics.Event('Navigation', 'Clicked Login');
        dispatch(actionShowLoginDialog(true));
    };

    const logoUrl = config.LOGO || '/assets/soar_logo.png';
    const subdomainLogoUrl = config.LOGO || '/assets/Soar+-logo_FINAL_white_crop.png';

    if (!showNavbar) {
        return <React.Fragment />;
    }

    return (
        <Navbar fixed="top">
            <NavBarBrandingContent>
                <SoarNavbarBrand onClick={onClickLogo}>
                    {SoarHelper.isSoar() ? (
                        <SoarLogo src={logoUrl} alt="Soar" data-testid="navbar-logo" />
                    ) : (
                        <SubdomainImg src={subdomainLogoUrl} alt="Soar" data-testid="navbar-logo-plus" />
                    )}
                </SoarNavbarBrand>
                {myProfile?.domains && <NavbarSubdomainList subdomains={myProfile.domains} />}
            </NavBarBrandingContent>

            <Nav>
                {myProfile ? (
                    <NavItem>
                        <BellContainer>
                            <Notifications />
                        </BellContainer>
                    </NavItem>
                ) : null}

                {userLoggedIn && myProfile ? (
                    <NavbarProfile userProfile={myProfile} isSoar={SoarHelper.isSoar()} />
                ) : (
                    <SignInNavItem>
                        <NavLink onClick={onClickLogin} data-cy={'Sign in'} data-testid="navbar-sign-in">
                            Sign in
                        </NavLink>
                    </SignInNavItem>
                )}
            </Nav>
        </Navbar>
    );
};

export default NavigationBar;

interface NavItemProps {
    selected?: boolean;
}

export const Navbar = styled(BootstrapNavbar)`
    background-color: rgba(0, 0, 0, 0.85) !important;
    backdrop-filter: blur(3px);
    height: 70px;
    margin-left: 0;
    z-index: 10025;
    color: white;
`;

const NavBarBrandingContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Nav = styled(BootstrapNavbar)`
    padding: 0.5rem 0 0.5rem 1rem;
    flex-wrap: nowrap;
`;

const NavItem = styled(BootstrapNavItem)<NavItemProps>`
    list-style: none;
    a {
        color: ${(props) => (props.selected ? '#EED926 !important' : 'white')};
    }

    &:hover {
        color: none;
    }
`;

const BellContainer = styled.div`
    margin-top: -8px;
`;
const SignInNavItem = styled(BootstrapNavItem)`
    color: white;
    list-style: none;
    min-width: 71px;

    &:hover {
        color: #eed926;
    }
`;

interface NavLinkProps {
    to?: string;
}

const NavLink = styled(BootstrapNavLink)<NavLinkProps>`
    color: white !important;
    cursor: pointer;
    &:hover {
        color: #eed926 !important;
    }
`;

const SoarNavbarBrand = styled(BootstrapNavbarBrand)`
    cursor: pointer;
    margin-top: -9px;
`;

const SoarLogo = styled.img`
    height: 48px;
`;

const SubdomainImg = styled.img`
    width: 134px;
    height: 48px;
    padding-top: 2px;
`;
