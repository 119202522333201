import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ListingHelper from '../../lib/listing-helper';
import Analytics from '../../lib/user-analytics';
import { selectBookmarkedMaps } from '../../store/Map/BookMarks/selectors';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-modal';
import { actionFetchBookmarkedMaps } from '../../store/Map/BookMarks/actions';
import { selectLoggedIn } from '../../store/Account/selectors';

interface SearchContentBookmarksProps {
    onSelectItem: (item: CarouselItem) => void;
}

const SearchContentBookmarks = (props: SearchContentBookmarksProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const bookmarkedMaps = useSelector(selectBookmarkedMaps);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!bookmarkedMaps?.length && isLoggedIn) {
            dispatch(actionFetchBookmarkedMaps());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    if (!bookmarkedMaps?.length || !isLoggedIn) return <React.Fragment />;

    const bookmarkedItems: CarouselItem[] = bookmarkedMaps.map((t) => {
        return {
            listingId: t.id,
            title: t.title,
            author: t.userName,
            previewUrl: ListingHelper.getPreviewUrlForListing(t.id),
            opacity: 1,
        };
    });

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/gold_medal.svg" />
                Your <span>bookmarked maps</span>
            </SegmentTitle>

            <CarouselContainer>
                <SearchContentCarousel
                    items={bookmarkedItems}
                    height="180px"
                    handleSelectItem={(item: CarouselItem) => {
                        props.onSelectItem(item);
                        Analytics.Event('Search Bar', 'Clicked Map From History', item.listingId);
                    }}
                />
            </CarouselContainer>
        </SegmentContainer>
    );
};

export default SearchContentBookmarks;

const CarouselContainer = styled.div``;
