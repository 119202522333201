/* eslint-disable @typescript-eslint/no-explicit-any */
import { LatLngBounds, LatLngExpression } from 'leaflet';
import { LatLng } from 'leaflet';
import Geometry from './geo-utils-shame';
import * as Wellknown from 'wellknown';
const L = require('leaflet');
const mgrs = require('mgrs');
const utmObj = require('utm-latlng');

// NEEDS AN OVERHAUL!

export default class GeoUtil {
    // https://gist.github.com/springmeyer/871897
    static latLngToEPSG3857(latLng: LatLng): [number, number] {
        const x = (latLng.lng * 20037508.34) / 180;
        let y = Math.log(Math.tan(((90 + latLng.lat) * Math.PI) / 360)) / (Math.PI / 180);
        y = (y * 20037508.34) / 180;
        return [x, y];
    }

    static EPSG3857ToLatLng(x: number, y: number): LatLng {
        const lng = (180 * x) / 20037508.34;
        const lat = (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
        return L.latLng(lat, lng);
    }

    // TODO:  Potential source of bugs?  Do consumers use `instanceof` correctly?
    static positionFromWKT(wkt: string): LatLng | LatLngBounds | undefined {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (parsedWKT.type === 'Polygon') {
                const north = parsedWKT.coordinates[0][1][1];
                const east = parsedWKT.coordinates[0][1][0];
                const south = parsedWKT.coordinates[0][3][1];
                const west = parsedWKT.coordinates[0][3][0];

                const northEast = new LatLng(north, east);
                const southWest = new LatLng(south, west);
                return new LatLngBounds(northEast, southWest);
            } else if (parsedWKT.type === 'Point') {
                return new LatLng(parsedWKT.coordinates[1], parsedWKT.coordinates[0]);
            }
            return undefined;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    // TODO: Potential source of bugs. Do consumers check for LatLng(0, 0)?
    static latLngFromWKT(wkt: any): LatLng {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLng(0, 0);
            }
            return new LatLng(parsedWKT.coordinates[1], parsedWKT.coordinates[0]);
        } catch (e) {
            return new LatLng(0, 0);
        }
    }

    static toLeafletPositionsClock(positions: number[][]): LatLng[] {
        return [
            new LatLng(positions[2][1], positions[2][0]),
            new LatLng(positions[3][1], positions[3][0]),
            new LatLng(positions[1][1], positions[1][0]),
            new LatLng(positions[0][1], positions[0][0]),
        ];
    }

    static toDistortablePositionsClock(positions: LatLng[]): number[][] {
        return [
            [positions[3].lng, positions[3].lat],
            [positions[2].lng, positions[2].lat],
            [positions[0].lng, positions[0].lat],
            [positions[1].lng, positions[1].lat],
            [positions[3].lng, positions[3].lat],
        ];
    }

    static polygonFromPolygonWKT(wkt: any): LatLng[] {
        const parsedWKT: any = Wellknown.parse(wkt);
        if (parsedWKT && parsedWKT.coordinates) {
            return parsedWKT.coordinates[0].map((coord) => {
                return new LatLng(coord[1], coord[0]);
            });
        }
        return [];
    }

    static polygonForBounds(bounds: LatLngBounds): LatLngExpression[] {
        const latlngs: LatLngExpression[] = [];
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getWest() });
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getWest() });
        return latlngs;
    }

    static latLngBoundsFromPolygonWKT(wkt: any): LatLngBounds {
        if (wkt === undefined) {
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }

        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
            }
            const north = parsedWKT.coordinates[0][1][1];
            const east = parsedWKT.coordinates[0][1][0];
            const south = parsedWKT.coordinates[0][3][1];
            const west = parsedWKT.coordinates[0][3][0];

            const northEast = new LatLng(north, east);
            const southWest = new LatLng(south, west);
            return new LatLngBounds(northEast, southWest);
        } catch (e) {
            console.log('Error parsing polygon WKT for bounding box');
            console.log(e);
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }
    }

    static latLngBoundsToWKT(latLngBounds: LatLngBounds): string {
        const coords = [
            latLngBounds.getNorthEast(),
            latLngBounds.getNorthWest(),
            latLngBounds.getSouthWest(),
            latLngBounds.getSouthEast(),
        ];
        const polygon = new L.polygon(coords);
        return Wellknown.stringify(polygon.toGeoJSON());
    }

    static widthKilometers(latLngBounds: LatLngBounds): number {
        const ne = latLngBounds.getNorthEast();
        const nw = latLngBounds.getNorthWest();
        return Geometry.distance([ne.lng, ne.lat], [nw.lng, nw.lat]);
    }

    static heightKilometers(latLngBounds: LatLngBounds): number {
        const ne = latLngBounds.getNorthEast();
        const se = latLngBounds.getSouthEast();
        return Geometry.distance([ne.lng, ne.lat], [se.lng, se.lat]);
    }

    static area(latLngBounds: LatLngBounds): number {
        const ne = [latLngBounds.getNorthEast().lat, latLngBounds.getNorthEast().lng];
        const nw = [latLngBounds.getNorthWest().lat, latLngBounds.getNorthWest().lng];
        const sw = [latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng];
        const se = [latLngBounds.getSouthEast().lat, latLngBounds.getSouthEast().lng];
        const areaSqm = Geometry.area([ne, nw, sw, se]);
        return areaSqm;
    }

    static polygonAsLatLngExpression(bounds: LatLngBounds): LatLngExpression[] {
        const latlngs: LatLngExpression[] = [];
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getWest() });
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getWest() });
        return latlngs;
    }

    static latLngListsToWKT(latLngs: LatLng[]): string {
        const polygon = new L.polygon(latLngs);
        return Wellknown.stringify(polygon.toGeoJSON());
    }

    static gpsLongitudeDMSToDecimalDegrees(gpsLongitudeDMS: any, longitudeRef: string) {
        if (gpsLongitudeDMS === undefined) {
            return undefined;
        }
        const direction = longitudeRef === 'E' ? 1 : -1;
        return (
            direction *
            (parseFloat(gpsLongitudeDMS[0]) +
                parseFloat(gpsLongitudeDMS[1]) / 60 +
                parseFloat(gpsLongitudeDMS[2]) / 3600)
        );
    }

    static gpsLatitudeDMSToDecimalDegrees(gpsLatitudeDMS: any, latitudeRef: string) {
        if (gpsLatitudeDMS === undefined) {
            return undefined;
        }
        const direction = latitudeRef === 'N' ? 1 : -1;
        return (
            direction *
            (parseFloat(gpsLatitudeDMS[0]) + parseFloat(gpsLatitudeDMS[1]) / 60 + parseFloat(gpsLatitudeDMS[2]) / 3600)
        );
    }

    static boundingBoxFromExifData(
        focalLengthIn35mmFilm: number,
        altitude: number,
        centerLatLng: LatLng,
        gimbalYawDegree: number,
        pixelXDimension: number,
        pixelYDimension: number
    ) {
        if (
            isNaN(focalLengthIn35mmFilm) ||
            isNaN(altitude) ||
            isNaN(gimbalYawDegree) ||
            isNaN(pixelXDimension) ||
            isNaN(pixelYDimension) ||
            altitude < 0
        )
            return undefined;

        const diagonalDistance = (altitude * 35) / focalLengthIn35mmFilm;

        const metersPerPixel =
            diagonalDistance / Math.sqrt(Math.pow(pixelXDimension, 2) + Math.pow(pixelYDimension, 2));

        const distanceHorizontal = metersPerPixel * pixelXDimension;
        const distanceVertical = metersPerPixel * pixelYDimension;

        const rotation = ((Math.PI * -gimbalYawDegree) / 180) % (2 * Math.PI);
        const corner_1 = this.rotationMatrix([-distanceHorizontal / 2, distanceVertical / 2], rotation);
        const corner_2 = this.rotationMatrix([distanceHorizontal / 2, distanceVertical / 2], rotation);
        const corner_3 = this.rotationMatrix([distanceHorizontal / 2, -distanceVertical / 2], rotation);
        const corner_4 = this.rotationMatrix([-distanceHorizontal / 2, -distanceVertical / 2], rotation);

        return [
            this.pairLocalToGlobal(corner_1, centerLatLng),
            this.pairLocalToGlobal(corner_2, centerLatLng),
            this.pairLocalToGlobal(corner_3, centerLatLng),
            this.pairLocalToGlobal(corner_4, centerLatLng),
            this.pairLocalToGlobal(corner_1, centerLatLng),
        ];
    }

    static pairLocalToGlobal(pair: number[], transform: LatLng) {
        const latDirection = pair[0] / Math.abs(pair[0]) + 1;
        const lngDirection = pair[1] / Math.abs(pair[1]) + 1;
        const lat = this.distanceToLatLng(pair[0], transform, latDirection ? 270 : 90)[0];
        const lng = this.distanceToLatLng(pair[1], transform, lngDirection ? 180 : 0)[1];
        return [lat, lng];
    }

    static distanceToLatLng(_distance: number, startPoint: LatLng, bearing: number) {
        const R = 6356752;
        const bearing_radians = (Math.PI * bearing) / 180;
        const start_lat = (Math.PI * startPoint.lat) / 180;
        const start_lng = (Math.PI * startPoint.lng) / 180;
        const distance = Math.abs(_distance);
        const end_lat = Math.asin(
            Math.sin(start_lat) * Math.cos(distance / R) +
                Math.cos(start_lat) * Math.sin(distance / R) * Math.cos(bearing_radians)
        );
        const end_lng =
            start_lng +
            Math.atan2(
                Math.sin(bearing_radians) * Math.sin(distance / R) * Math.cos(start_lat),
                Math.cos(distance / R) - Math.sin(start_lat) * Math.sin(end_lat)
            );

        return [(180 * end_lng) / Math.PI, (180 * end_lat) / Math.PI];
    }

    static rotationMatrix(pair: number[], theta: number) {
        return [
            pair[0] * Math.cos(theta) - pair[1] * Math.sin(theta),
            pair[0] * Math.sin(theta) + pair[1] * Math.cos(theta),
        ];
    }

    static quickArea(bounds: LatLngBounds): number {
        const width = Math.abs(bounds.getWest() + 180 - (bounds.getEast() + 180) - 180);
        const height = Math.abs(bounds.getNorth() + 90 - (bounds.getNorth() + 90) - 90);
        return width * height;
    }

    /**
     * Returns true if A contains B
     * @param boundsA
     * @param boundsB
     * @returns
     */
    static contains(boundsA: LatLngBounds, boundsB: LatLngBounds): boolean {
        return (
            boundsA.contains(boundsB.getNorthEast()) &&
            boundsA.contains(boundsB.getNorthWest()) &&
            boundsA.contains(boundsB.getSouthWest()) &&
            boundsA.contains(boundsB.getSouthEast())
        );
    }

    /**
     * Returns true is A includes B
     * @param boundsA
     * @param boundsB
     * @returns
     */
    static includes(boundsA: LatLngBounds, boundsB): boolean {
        return (
            boundsA.contains(boundsB.getNorthEast()) ||
            boundsA.contains(boundsB.getNorthWest()) ||
            boundsA.contains(boundsB.getSouthWest()) ||
            boundsA.contains(boundsB.getSouthEast())
        );
    }

    static wrapBoundingBox(bounds: LatLngBounds): LatLngBounds {
        const ne = bounds.getNorthEast().wrap();
        const sw = bounds.getSouthWest().wrap();

        return new LatLngBounds(sw, ne);
    }

    /**
     * Compares the area of intersection between two LatLngBounds and
     * returns true if the target approximately contains the target.
     * Used by the continental clustering to group maps that reasonably belong to that group
     *
     * @param targetBounds The Target bounds, such as the continent
     * @param sourceBounds The Source bounds, such as the map being tested
     * @param intersectionThresholdPercentage The percentage it should overlap, default at 50%
     * @returns true if the target approximately contains the source
     */
    static approximatelyContains(
        targetBounds: LatLngBounds,
        sourceBounds: LatLngBounds,
        intersectionThresholdPercentage?: number
    ): boolean {
        const intersectionArea = GeoUtil.intersectionArea(targetBounds, sourceBounds);

        if (intersectionArea === 0) return false;

        const sourceArea = GeoUtil.area(sourceBounds);
        const intersectionRatio = intersectionArea / sourceArea;

        const defaultIntersectionThreshold = 50; //percent
        const threshold = intersectionThresholdPercentage || defaultIntersectionThreshold;
        return intersectionRatio > threshold / 100;
    }

    /**
     * Calculates the intersection area of two Bounding boxes using axis-aligned rectangle logic
     * @param targetBounds The Target bounds, such as the continent
     * @param sourceBounds The Source bounds, such as the map being tested
     * @returns The area of the intersection
     */
    static intersectionArea(targetBounds: LatLngBounds, sourceBounds: LatLngBounds): number {
        // The bounds do not intersect at all
        if (!sourceBounds.intersects(targetBounds)) {
            return 0;
        }

        // The bounds are completely inside the target so the source is the intersection
        if (
            targetBounds.contains(sourceBounds.getNorthWest()) &&
            targetBounds.contains(sourceBounds.getNorthEast()) &&
            targetBounds.contains(sourceBounds.getSouthWest()) &&
            targetBounds.contains(sourceBounds.getSouthEast())
        ) {
            return GeoUtil.area(sourceBounds);
        }

        // Convert to rectangle points for easier reading
        const latLngBoundsToPoints = (
            bounds: LatLngBounds
        ): { left: number; right: number; top: number; bottom: number } => {
            return {
                left: bounds.getWest(),
                right: bounds.getEast(),
                top: bounds.getNorth(),
                bottom: bounds.getSouth(),
            };
        };

        const targetPoints = latLngBoundsToPoints(targetBounds);
        const sourcePoints = latLngBoundsToPoints(sourceBounds);

        // Calculate intersection of axis-aligned rectangle
        const leftIntersect = Math.max(targetPoints.left, sourcePoints.left);
        const rightIntersect = Math.min(targetPoints.right, sourcePoints.right);
        const bottomIntersect = Math.max(targetPoints.bottom, sourcePoints.bottom);
        const topIntersect = Math.min(targetPoints.top, sourcePoints.top);

        const intersectsBounds = new LatLngBounds(
            new LatLng(topIntersect, leftIntersect),
            new LatLng(bottomIntersect, rightIntersect)
        );

        return GeoUtil.area(intersectsBounds);
    }

    /**
     * We say a map is global if it approximately covers the entire world's bounding box
     * The thresholds are just reasonable guesses based on the content
     * @param bounds The LatLngBounds
     * @returns true if the bounds can be reasonably considered global
     */
    static isApproximatelyGlobalBounds = (bounds: LatLngBounds): boolean => {
        return bounds.getNorth() > 50 && bounds.getSouth() < -50 && bounds.getWest() < -40 && bounds.getEast() > 40;
    };

    static getCoordinateFromSearchTerm = (search: string): LatLng | null => {
        search = search.trim();

        // Check for DMS format
        const dmsCoords = GeoUtil.parseDMS(search);
        if (dmsCoords) return dmsCoords;

        // Check for lat lng format
        const latLng = GeoUtil.parseLatLng(search);
        if (latLng) return latLng;

        // Check for UTM format
        const utmCoords = GeoUtil.parseUTM(search);
        if (utmCoords) return utmCoords;

        // Check for MGRS format
        const mgrsCoords = GeoUtil.parseMGRS(search);
        if (mgrsCoords) return mgrsCoords;

        return null;
    };

    static parseDMS = (search: string): LatLng | null => {
        const dmsRegex = /(\d+[°]\d+[′]\d+(\.\d+)?[″]?[NSEW])/g;
        const dmsMatches = search.match(dmsRegex);

        if (dmsMatches && dmsMatches.length === 2) {
            const lat = GeoUtil.parseDMSParts(dmsMatches[0]);
            const lng = GeoUtil.parseDMSParts(dmsMatches[1]);

            if (!(lat > -90 && lat < 90 && lng > -180 && lng < 180)) return null;

            return new LatLng(lat, lng);
        }

        return null;
    };

    static parseDMSParts = (dms: string): number => {
        const dmsRegex = /(\d+)[°](\d+)?[′]?(\d+(?:\.\d+)?)[″]?([NSEW])/;
        const parts = dms.match(dmsRegex);

        if (!parts) return NaN;

        const degrees = parseFloat(parts[1]);
        const minutes = parseFloat(parts[2] || '0');
        const seconds = parseFloat(parts[3] || '0');
        const direction = parts[4];

        let decimal = degrees + minutes / 60 + seconds / 3600;

        if (direction === 'S' || direction === 'W') {
            decimal *= -1;
        }

        return decimal;
    };

    static parseLatLng = (search: string): LatLng | null => {
        let split;

        // Separated by a comma
        if (search.includes(',')) {
            split = search
                .replace(' ', '')
                .split(',')
                .filter((word) => word !== '');
        }
        // Separated by white space
        else {
            split = search.split(' ').filter((word) => word !== '');
        }

        if (split.length === 2) {
            const lat = parseFloat(split[0]);
            const lng = parseFloat(split[1]);

            if (!(lat > -90 && lat < 90 && lng > -180 && lng < 180)) return null;

            if (!isNaN(lat) && !isNaN(lng)) {
                return new LatLng(lat, lng);
            }
        }

        return null;
    };

    static parseUTM = (search: string): LatLng | null => {
        const utmRegex = /(\d+)\s?([C-X])\s?(\d+)\s?(\d+)/i;
        const parts = search.match(utmRegex);

        if (!parts) return null;

        const zone = parseInt(parts[1], 10);
        const hemisphere = parts[2] > 'M' ? 'N' : 'S';
        const easting = parseFloat(parts[3]);
        const northing = parseFloat(parts[4]);

        const utmConverter = new utmObj();
        const latLng = utmConverter.convertUtmToLatLng(easting, northing, zone, hemisphere);
        return new LatLng(latLng.lat, latLng.lng);
    };

    static parseMGRS = (search: string): LatLng | null => {
        try {
            const [lng, lat] = mgrs.toPoint(search);
            if (!(lat > -90 && lat < 90 && lng > -180 && lng < 180)) return null;
            return new LatLng(lat, lng);
        } catch {
            return null;
        }
    };

    /**
        Returns the bearing in degrees clockwise from north (0 degrees)
        from the first L.LatLng to the second, at the first LatLng
        see: https://github.com/makinacorpus/Leaflet.GeometryUtil/blob/master/src/leaflet.geometryutil.js
    */
    static bearing = (start: L.LatLng, end: L.LatLng): number => {
        const rad = Math.PI / 180,
            lat1 = start.lat * rad,
            lat2 = end.lat * rad,
            lon1 = start.lng * rad,
            lon2 = end.lng * rad,
            y = Math.sin(lon2 - lon1) * Math.cos(lat2),
            x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

        const bearing = ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360;
        return bearing >= 180 ? bearing - 360 : bearing;
    };
}

export const wktPolygonToLatLngArray = (wkt?: string): LatLng[] | null => {
    if (!wkt) {
        return null;
    }
    const wktPolygonRegex = /^POLYGON\s*\(\((.+)\)\)$/;
    const match = wktPolygonRegex.exec(wkt);

    if (!match) {
        // Invalid WKT just return null
        return null;
    }

    try {
        // Extract and split the coordinate pairs
        const coordinates = match[1].split(',').map((coord) => coord.trim().split(' ').map(Number));
        // Check if all coordinate pairs are valid
        if (coordinates.some((pair) => pair.length !== 2 || isNaN(pair[0]) || isNaN(pair[1]))) {
            return null;
        }
        // Convert the coordinate pairs to Leaflet LatLng objects
        const latLngs = coordinates.map(([lng, lat]) => new LatLng(lat, lng));
        return latLngs;
    } catch (error) {
        // Just return null if anything goes wrong
        return null;
    }
};
