import { DefaultTheme, css } from 'styled-components';

/**
 * The concrete theme should only include the bare minimum of properties. Think of it like constants.
 * Before adding to it, think carefully if this property belongs to the entire application or not.
 *
 * If you require some properties to be shared between disparate components, consider using the `css` function. For example;
 *
 *     const border = css`
 *          border: 1px solid rgba(255, 255, 255, 0.6);
 *     `;
 *
 *     const ItemContainer = styled.div`
 *         ${border}
 *     `;
 *
 */

const theme: DefaultTheme = {
    color: {
        red: '#ff0000',
        yellow: '#eed926',
        opaqueYellow: `rgba(238, 217, 35, 0.8)`,
        gray: '#343a40',
        lightGray: '#d3d3d3',
        white: '#ffffff',
        opaqueWhite: 'rgba(255, 255, 255, 0.8)',
        transparentDark: 'rgba(0, 0, 0, 0.8)',
        background: '#181a1a',
    },
    fontSize: {
        small: '0.8rem',
        normal: '1rem',
        large: '1.2rem',
    },
    borderRadius: '6px',
    zIndex: {
        nav: 10025,
        drawer: 1000,
        modal: 10000,
        toast: 10001,
    },
};

export { theme };

export const lightDropShadow = css`
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);
    border: 1px solid #515151;
`;
