import { useSelector } from 'react-redux';
import { selectSearchDrawListings } from '../../../../store/Map/MapSelection/selectors';
import { selectHighlightedStoaryWKT } from '../../../../store/Map/DrawStory/selectors';
import MapSearchPreview from '../PolygonCluster/map-search-preview';

const MapSearchDrawProvider = () => {
    // TODO this is ok but needs work
    const listings = useSelector(selectSearchDrawListings);
    const highlightedStoariesWkt = useSelector(selectHighlightedStoaryWKT);
    return <MapSearchPreview listings={listings || []} wkt={highlightedStoariesWkt} />;
};

export default MapSearchDrawProvider;
